<template>
    <div class="cfa-root">
      <c-f-a-nav :alert="alertString" contentClass="position-relative">
        <template v-slot:title>
          <div class="w-100 pt-5 text-start mt-2 mb-3 d-flex justify-content-between align-items-center">
            <h1 class="cfa-section-title">Micro-Learning</h1>
            <div>
              <button @click="this.newModule=true" class="btn cfa-btn-primary me-2">
                CRÉER UN NOUVEAU MICRO-APPRENTISSAGE <i class="fa fa-plus">&nbsp;</i>
              </button>
            </div>
          </div>
        </template>
        <template v-slot:content>
          <div v-show="!waitingAPIResponse" class="cfa-page">
            <div>
                            <content-filter @viewMode="getViewMode" @viewFilter="getViewFilter" :total1="totalModule" :total2="totalInProgress"></content-filter>
                          </div>
                   <div v-if="this.allModules.length == 0" class="d-flex justify-content-center align-items-center" style="min-height: 70vh">
                 <div class="d-flex flex-column">
                <img src="@/assets/img/svg/cfa-ud-assets-05.svg" alt="empty content" style="width: 15em;"/>
                <span class="mt-3 fs-5 fw-bold">Oups ! Rien pour le moment.</span>
              </div>
            </div>
            <div v-else>
              <div class="row pt-3">
                <course-card  v-for="module, i in this.allModules" v-bind:key="i" @update="getAllModule" :data="module"  :id="module.id" :type="module.title"  :preview="module.cover_image" :label="'Micro Learning '+(i+1)" :started="(i!=0) ? false : true" :title="module.micro_learning_url" :description="module.description" :max__horaire="module.duration" :viewMode='displayMode' :filterMode="filterMode"   @sync="getAllModule" :uid="'cfa-crud-module-'+i"></course-card>
              </div>
            </div>
            <!-- <div class="tab mt-4">
                            <div class="tab-header">
                                <ul class="tab-ul">
                                    <li :class="{ 'active': currentTab === 'declaration' }" @click="currentTab = 'declaration'" class="d-md-flex d-none">Micro-learning </li>
                                    <li :class="{ 'active': currentTab === 'template' }" @click="currentTab = 'template'" class="d-md-flex d-none"> {{$t('list_of_micro_awareness')}} </li>
                                    
                                    <li :class="{ 'active': currentTab === 'groups' }" @click="currentTab = 'groups'" class="d-md-none d-flex"> Groupes (1)</li>
                                </ul>
                                <div class="d-flex align-items-center justify-content-end p-1">
                                    
                                    <button v-if="currentTab === 'declaration'" @click="this.newModule=true" class="btn cfa-btn-primary me-2">
                                    CRÉER UN NOUVEAU MICRO-APPRENTISSAGE <i class="fa fa-plus">&nbsp;</i>
                                    </button>
                                    
                                </div>
                            </div>
                            <div class="tab-body">
                                <div v-if="currentTab === 'declaration'">
                                 
                                </div>
                                <div v-if="currentTab === 'template'">
                                 
                                  <Table :hide-control="(campaignsAll.length == 0) ? true :  false" :i="4" :columns="campaignsAllColumns" :data="campaignsAll" :columns-action="campaignsAllToListen" @action="getCallToActionForCampaigns" :customized-default-image="true" >
                                  <template v-slot:defaultImage>
                                  <img src="@/assets/img/gif/campagne.gif" alt="empty content" style="width: 11em;"/>
                                  <span class="mt-3 fs-5 fw-bold pl-2 text-center empty-label">Aucune campagne !</span>
                                  </template>
                               </Table>
                                </div>
                                
                                
                            </div>
                        </div> -->
            
          </div>
          <div v-if="waitingAPIResponse" class="cfa-page">
                        <div class="d-flex justify-content-center align-items-center" style="min-height: 70vh">
                            <div class="preloader_center d-flex flex-column">
                                <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
                                <p class="pl-3 fw-bold">Loading...</p>
                            </div>
                        </div>
                    </div>
        </template>
      </c-f-a-nav>
      <!--footer-->
      <c-f-a-footer></c-f-a-footer>
      <scalable-modal  @callback="wizardUpdatedss" :break="exitOrgUpdateModal3" label="CRÉER UN PLAN" uid="cfa-modal-phish-template-creates" @close="newDomainForm3=false" :open="newDomainForm3"  :advancedControl="true" validationButtonText="Nouveau email campaign" exitButtonText="Annuler" displayMode="flex" :style="'width: 60em; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
                    <template v-slot:content>
                       
                        
                        <div class="row w-100">
                            <div class="form-group py-2 pe-0 pe-md-2 ">
                                <p class="mb-2"><strong>url shortener</strong></p>
                                <div class="d-flex flex-row">
                                    <select class="form-control" v-model="selectedurlshortener" >
  <option selected disabled value="">Sélectionnez url shortener</option>
  <option v-for="declare in declarationData" :value="declare.id" v-bind:key="declare.name">{{declare.name}}</option>
</select>
                                </div>
                            </div>
                        </div>
                        
                        
                    </template>
                </scalable-modal>
                <scalable-modal @callback="wizardUpdateds" :break="exitOrgUpdateModal2" label="CRÉER UN PLAN" uid="cfa-modal-phish-template-create" @close="newDomainForm2=false" :open="newDomainForm2"  :advancedControl="true" validationButtonText="Nouveau campaign" exitButtonText="Annuler" displayMode="flex" :style="'width: 60em; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
                    <template v-slot:content>
                        <!-- blo1 -->
                        
                        <div class="row w-100">
                            <div class="form-group py-2 pe-0 pe-md-2 ">
                                <p class="mb-2"><strong>url shortener</strong></p>
                                <div class="d-flex flex-row">
                                    <select class="form-control" v-model="selectedurlshortener" >
  <option selected disabled value="">Sélectionnez url shortener</option>
  <option v-for="declare in declarationData" :value="declare.id" v-bind:key="declare.name">{{declare.name}}</option>
</select>
                                </div>
                            </div>
                        </div>
                        <div class="row w-100">
                            <div class="form-group py-2 pe-0 pe-md-2 ">
                                <p class="mb-2"><strong>sms provider</strong></p>
                                <div class="d-flex flex-row">
                                    <select class="form-control" v-model="selectedsmsprovider" >
  <option selected disabled value="">Sélectionnez sms provider</option>
  <option v-for="declare in sms_provider" :value="declare.id" v-bind:key="declare.name">{{declare.name}}</option>
</select>
                                </div>
                            </div>
                        </div>
                        
                    </template>
                </scalable-modal>
      <!-- create module -->
      <scalable-modal @callback="addNewMicroLearning" :break="exitModuleModal" label="AJOUTER UN MODULE" uid="cfa-modal-003" @close="newModule=false" :open="newModule" :advancedControl="true" validationButtonText="Créer ce module" exitButtonText="Annuler" :style="'width: 75%; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
        <template v-slot:content>
          <!-- blo1 -->
          <div class="row w-100">
            <div class="col-8">
              <!--<div class="mt-2">
                <modern-input @inputChange="(e)=>{this.connect_email = e}" placeholder="Votre Adresse email" errorText="Champ requis" uid="cfa-username-bloc" type="email" css></modern-input>
              </div>-->
              <div class="form-group py-2 pe-0 pe-md-2 ">
                  <p class="mb-2"><strong>TITRE</strong></p>
                  <div class="d-flex flex-row">
                    <input v-model="module_title" class="form-control" type="text" placeholder="entrez le titre">
                  </div>
                </div>
              <div class="mt-2">
                <p class="mb-2"><strong>DESRIPTION</strong></p>
                <textarea v-model="module_description" class="descriptionArea"></textarea>
              </div>
            </div>
            <div class="col-4 pe-0">
              <div>
                <div class="form-group py-2 pe-0 pe-md-2 ">
                  <p class="mb-2"><strong>MICRO LEARNING URL</strong></p>
                  <div class="d-flex flex-row">
                    <input v-model="module_microlearning_url" class="form-control" type="text" placeholder="entrez l'URL du micro-apprentissage">
                  </div>
                </div>
                <div class="form-group py-3 pe-0 pe-md-2 ">
                  <p class="mb-2"><strong>DURÉE</strong></p>
                  <div class="d-flex flex-row">
                    <input v-model="module_time" class="form-control" type="number" placeholder="(seconds)">
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div class="row w-100 mt-3">
            <div class="form-group py-3 pe-0 pe-md-2 ">
              <p class="mb-2"><strong>IMAGE DE COUVERTURE</strong></p>
              <image-input @image-loaded="onImageLoaded"/>
            </div>
          </div>
  
          <div class="row w-100 mt-3">
            <div class="form-group py-3 pe-0 pe-md-2 ">
              <p class="mb-2"><strong>MESSAGE E-MAIL PRINCIPAL</strong></p>
              <div class="d-flex flex-row">
                <input v-model="module_top_message" class="form-control" type="text" placeholder="entrez le premier e-mail">
              </div>
            </div>
          </div>
  
          <div class="row w-100 mt-3">
            <div class="form-group py-3 pe-0 pe-md-2 ">
              <p class="mb-2"><strong>MESSAGE E-MAIL EN BAS</strong></p>
              <div class="d-flex flex-row">
                <input v-model="module_bottom_message" class="form-control" type="text" placeholder="entrez le message électronique en bas">
              </div>
            </div>
          </div>

          <div class="row w-100 mt-3">
            <div class="form-group py-3 pe-0 pe-md-2 ">
              <p class="mb-2"><strong>SMS MESSAGE</strong></p>
              <div class="d-flex flex-row mb-2">
                <input v-model="module_sms_message" class="form-control" type="text" placeholder="saisir un SMS">
              </div>
            </div>
          </div>
          <div class="row w-100 mt-3">
            <div class="mt-2">
                <p class="mb-2"><strong>IM MESSAGE</strong></p>
                <textarea v-model="module_im_message" class="descriptionArea"></textarea>
              </div>
          </div>
        </template>
      </scalable-modal>
      <scalable-modal :disable-control="true" @callback="confirmUpdate" :break="exitOrgUpdateModal" label="CRÉER UN PLAN" uid="cfa-modal-phish-campaign-update" @close="newcampaignUpdate=false" :open="newcampaignUpdate"  :advancedControl="true" validationButtonText="Nouvelle sms campagne" exitButtonText="Annuler" displayMode="flex" :style="'width: 60em; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
            <template v-slot:content>
              <!--navigable-tabs | removed from the wizard-->
                <Wizard
                    squared-tabs
                    card-background
                    
                    scrollable-tabs
                    :nextButton="nextButtonOptionss"
                    :backButton="prevButtonOptionss"
                    :doneButton="doneButtonOptions"
                    :custom-tabs="[
                        {
                          title: 'Initialisation',
                        },
                        {
                          title: 'Choix de la cible',
                        },
                        {
                          title: 'Groupe',
                        },
                        {
                          title: 'Plannification',
                        },
                        
                    ]"
                    :beforeChange="onTabBeforeChange"
                    @change="onChangeCurrentTab"
                    @complete:wizard="wizardUpdated"
                >
                    <transition-group name="slide-fade--">
                      <div v-if="currentTabIndex === 0" class="stepper-body">
                          <div class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Nom</strong></p>
                                  <div class="d-flex flex-row">
                                    <input class="form-control" type="text" placeholder="Choisissez un nom unique pour votre campagne" v-model="campaignByid.name">
                                     
                                  </div>
                              </div>
                          </div>
                         
                          <div class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Titre</strong></p>
                                  <div class="d-flex flex-row">
                                      <select class="form-control" v-model="micro_learning_id">
                                          <option  selected disabled>Selectionner un microlearning cible pour la campagne</option>
                                          <option v-for="group in microlearning" :value="group.id" v-bind:key="group">{{ group.title }}</option>
                                      </select>
                                  </div>
                                  
                              </div>
                          </div>
                          <div class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Campaign Type</strong></p>
                                  <div class="d-flex flex-row">
                                       <select class="form-control" v-model="campaignByid.campaign_type" id="selectType">
                                          <option value="default" selected disabled>Selectionner le type de campagne</option>
                                          <option value="sms">SMS</option>
                                          <option value="email">EMAIL</option>
                                      </select>
                                  </div>
                              </div>
                          </div>
                          
                      </div>
                      <div v-else-if="currentTabIndex === 1" class="stepper-body">
                        <div v-if="campaignByid.campaign_type == 'sms'" class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Nom de l'expéditeur du SMS</strong></p>
                                  <div class="d-flex flex-row">
                                    <input class="form-control" type="text" placeholder="Entrez le nom de l'expéditeur du SMS" v-model="campaignByid.sms_send_name">
                                  </div>
                              </div>
                          </div>
                          <div v-if="campaignByid.campaign_type == 'email'" class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Nom de l'expéditeur de l'e-mail</strong></p>
                                  <div class="d-flex flex-row">
                                    <input class="form-control" type="text" placeholder="Entrez le nom de l'expéditeur de l'e-mail" v-model="campaignByid.email_send_name">
                                  </div>
                              </div>
                          </div>
                          <div v-if="campaignByid.campaign_type == 'email'" class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Signature de l'expéditeur de l'e-mail</strong></p>
                                  <div class="d-flex flex-row">
                                    <input class="form-control" type="text" placeholder="Saisissez la signature de l'expéditeur de l'e-mail" v-model="campaignByid.email_send_signature">
                                  </div>
                              </div>
                          </div>
                          <div v-if="campaignByid.campaign_type == 'sms'" class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Signature de l'expéditeur du SMS</strong></p>
                                  <div class="d-flex flex-row">
                                    <input class="form-control" type="text" placeholder="Saisir la signature de l'expéditeur du SMS" v-model="campaignByid.sms_send_signature">
                                  </div>
                              </div>
                          </div>
                          <div class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Campaign Target</strong></p>
                                  <div class="d-flex flex-row">
                                       <select class="form-control" v-model="newCampaignTarget" id="selectType">
                                          <option  selected disabled>Selectionner le target de campagne</option>
                                          <option value="GROUP">Group</option>
                                          <option value="SERVICE">Service</option>
                                      </select>
                                  </div>
                              </div>
                          </div>
                      </div>
                      
                      <div v-else-if="currentTabIndex === 2" class="stepper-body">
                          <div v-if="newCampaignTarget ==  'GROUP'" class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Groupes cibles</strong></p>
                                  <div class="d-flex flex-row">
                                      <select class="form-control" v-model="campaignByid.group">
                                          <option :value="null" selected disabled>Selectionner un groupe cible pour la campagne</option>
                                          <option v-for="group in groupAllData" :value="group.id" v-bind:key="group">{{ group.name }}</option>
                                      </select>
                                  </div>
                              </div>
                          </div>
                          <div v-if="newCampaignTarget ==  'SERVICE'" class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Service</strong></p>
                                  <div class="d-flex flex-row">
                                      <select class="form-control" v-model="campaignByid.service">
                                          <option :value="null" selected disabled>Selectionner un service cible pour la campagne</option>
                                          <option v-for="service in serviceAllData" :value="service.id" v-bind:key="service">{{ service.name }}</option>
                                      </select>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div v-else-if="currentTabIndex === 3" class="stepper-body">
                          <div class="row w-100 m-inherit mt-2">
                              <div class="form-group py-2 px-0">
                                  <p class="mb-2"><strong>Date de planification <span style="color: red; opacity: 0.5; font-size: 0.9em;">(un délai d'attente de 03 heures sera ajouté à l'heure de lancement choisie)</span></strong></p>
                                  <div class="d-flex flex-row">
                                      <VueDatePicker placeholder="Choisir la date de planification" v-model="planification_date" locale="fr" cancelText="Annuler" selectText="Choisir" :title="'DEFINIR UNE DATE'" :minDate="minDate"></VueDatePicker>
                                  </div>
                              </div>
                          </div>
                           <div class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Message par défaut</strong></p>
                                  <div class="d-flex flex-row">
                                    <input class="form-control" type="text" placeholder="Entrez le message par défaut" v-model="campaignByid.default_message">
                                     
                                  </div>
                              </div>
                           </div>
  
                      </div>
                      
                    </transition-group>
                </Wizard>
            </template>
          </scalable-modal>
    </div>
  </template>
  
  <script>
  import CFAFooter from '@/components/nav/CFAFooter.vue'
  import CFANav from '@/components/nav/CFANav.vue'
  // import ContentFilter from '@/components/shared/ContentFilter.vue'
  import CourseCard from '@/components/shared/course/CourseCard3.vue'
  import { getAPI } from '@/axios-api.js'
  // import Table from "@/components/shared/table/Table.vue";
//   import DataTable from 'primevue/datatable';
// import Column from 'primevue/column';
import 'form-wizard-vue3/dist/form-wizard-vue3.css'
  import Wizard from 'form-wizard-vue3'
  import VueDatePicker from '@vuepic/vue-datepicker';
  // import PreLoader from '@/components/shared/PreLoader.vue'
  import ScalableModal from '@/components/shared/modal/ScalableModal.vue'
  // import ModernInput from '@/components/shared/input/ModernInput.vue'
  import ImageInput from '@/components/shared/input/ImageInput.vue'
  // import { Translate } from '@google-cloud/translate';

  
  export default {
    name: 'DashboardView',
    components: {
      CFAFooter,
    //   DataTable,
    // Column,
      // Table,
      Wizard,
    VueDatePicker,
      CFANav,
      CourseCard,
      ScalableModal,
      ImageInput,
      
    },
    data () {
      return {
        allModules: [],
        allTags: [],
        alertString: null,
        displayMode: 'GROUP',
        filterMode: 'ALL',
        alertMe: false,
        gAlertMessage: '',
        gAlertType: '',
        waitingAPIResponse: false,
        newModule: false,
        newTags: false,
        tag: null,
        currentTabIndex: 0,
        campaignsAll: [],
        campaignsAllOk: [],
        campaignsAllColumns: ['Nom de la campaigne','Statut', 'Type','Cible', 'Date création','Date validation', 'Actions'],
        campaignsAllToListen: ['Actions'],
        exitTagModule: null,
        exitModuleModal: null,
        exitOrgUpdateModal2:null,
      exitOrgUpdateModal:null,
      exitOrgUpdateModal3:null,
      newcampaignUpdate:false,
        module_tagSelect1: '',
        campaignByid:[],
        module_tagSelect2: '',
        module_tagSelect3: '',
        newDomainForm3:false,
        newDomainForm2:false,
        module_target: '',
        currentTab: 'declaration',
        module_content: '',
        module_video: '',
        module_title: '',
        module_bottom_message:'',
        module_description: '',
        module_sms_message:'',
        module_time: '',
        module_top_message:'',
        
        module_im_message:'',
        module_microlearning_url:'',
        module_image_cover: null,
        totalModule: 0,
        totalInProgress: 0,
      }
    },
    methods: {
      removeTag (e) {
        for (let i = 0; i < this.allTags.length; i++) {
          if (this.allTags[i].id === e) {
            this.allTags.splice(i, 1);
            break;
          }
        }
      },
      onChangeCurrentTab(index, oldIndex) {
            console.log(index, oldIndex);
            this.currentTabIndex = index;
          },
          onTabBeforeChange() {
            if (this.currentTabIndex === 0) {
                console.log('First Tab');
            }
            console.log('All Tabs');
          },
      getViewMode (e) {
        this.displayMode = e
      },
      getViewFilter (e) {
        this.filterMode = e
      },
      publishModule (bool) {
        this.alertString = `Module ${(bool ? 'publié' : 'désactivé')} avec succès|alert-yes|4000::`+Date.now()
      },
      
      callUpdatecampaignForms(data){
      this.getCallToActionForCampaignss(data);
     
                
                  this.newDomainForm2 = true;
                    
      //this.newDomainForm2=true;
     //this.newcampaignUpdate=true;
    //  this.getCallToActionForCampaignss(data);
     //this.wizardUpdateds();
     console.log("i",data);
    },
    callUpdatecampaignFormss(data){
      this.getCallToActionForCampaignss(data);
     
       
              this.newDomainForm3 = true;
              
                
      //this.newDomainForm2=true;
     //this.newcampaignUpdate=true;
     this.getCallToActionForCampaignss(data);
     //this.wizardUpdateds();
     console.log("i",data);
    },
    getCallToActionForCampaignss (payload) {
            // if(payload.action_type == 'delete') {
            //     this.openDeletionCampaignBox = true;
            //     this.tempDeleteID = payload.id;
            //     this.tempDeleteName = payload.name;
            
            // }
            
              //  this.newcampaignUpdate = true;
               this.tempDeleteID = payload;
               getAPI.get(`micro_learning/campaigns/${payload.id}`)
                  .then(response => {
                      if(response.status == 200) {
                          
                        this.campaignByid = response.data;
                         console.log(this.campaignByid.micro_learning.id);
                         this.micro_learning_id=this.campaignByid.micro_learning.id;
                         this.planification_date=this.campaignByid.planification_date
                      }
                      else {
                          // let msg = 'Oups ! something went wrong.'
                          // this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      
                  })
                  .catch(error => {
                      if (error.response) {
                          //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          // let msg = 'The request was made but no response was received. Please check your network.'
                          // this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          // let msg = 'Oups ! something went wrong.'
                          // this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      
                  })
           
  },
  callUpdatecampaignForm(data){
     this.newcampaignUpdate=true;
     this.getCallToActionForCampaignss(data);
     console.log(data);
    },
  getCallToActionForCampaigns (payload) {
            if(payload.action_type == 'edit') {
              console.log("data",payload);
                this.callUpdatecampaignForm(payload);
                // this.tempDeleteID = payload.id;
                // this.tempDeleteName = payload.name;
            
            }
            if(payload.action_type == 'validate') {
               //this.newcampaignUpdate = true;
               this.tempDeleteID = payload.id;
               this.waitingAPIResponse = true
        getAPI.get(`micro_learning/campaign/report/${this.tempDeleteID}/`,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
                'responseType': 'blob' 
        }
        )
        .then(response => {
            if(response.status == 200){
                const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download');
                    document.body.appendChild(link);
                    link.click();
                }
          else {
            let msg = 'Oups ! something went wrong.'
            this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          setTimeout(() => {
            this.waitingAPIResponse = false
          }, 2000);
        })
        .catch(error => {
          if (error.response) {
            let firstError = Object.values(error.response.data)[0][0]
            this.alertString = `${firstError}|alert-no|10000::`+Date.now()
          }
          else if (error.request) {
            let msg = 'The request was made but no response was received. Please check your network.'
            this.alertString = `${msg}|alert-no|8000::`+Date.now()
          }
          else {
            let msg = 'Oups ! something went wrong.'
            this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          this.waitingAPIResponse = false
        })
            }
          },
      getAllModule () {
        this.waitingAPIResponse = true
        getAPI.get('micro_learning/courses/')
        .then(response => {
          console.log(response)
          if(response.status == 200) {
            let modulesData = response.data
            this.allModules = modulesData.results
            this.totalModule = modulesData.count
            // TODO: make this dynamic
            this.totalInProgress = 1
          }
          else {
            let msg = 'Oups ! something went wrong.'
            this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          setTimeout(() => {
            this.waitingAPIResponse = false
          }, 2000);
        })
        .catch(error => {
          if (error.response) {
            let firstError = Object.values(error.response.data)[0][0]
            this.alertString = `${firstError}|alert-no|10000::`+Date.now()
          }
          else if (error.request) {
            let msg = 'The request was made but no response was received. Please check your network.'
            this.alertString = `${msg}|alert-no|8000::`+Date.now()
          }
          else {
            let msg = 'Oups ! something went wrong.'
            this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          this.waitingAPIResponse = false
        })
      },
      getAllTabs () {
        getAPI.get('tags')
            .then(response => {
              if(response.status == 200) {
                this.allTags = response.data.results
              }
              else {
                let msg = 'Oups ! something went wrong.'
                this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
            })
            .catch(error => {
              if (error.response) {
                let firstError = Object.values(error.response.data)[0][0]
                this.alertString = `${firstError}|alert-no|10000::`+Date.now()
              }
              else if (error.request) {
                let msg = 'The request was made but no response was received. Please check your network.'
                this.alertString = `${msg}|alert-no|8000::`+Date.now()
              }
              else {
                let msg = 'Oups ! something went wrong.'
                this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
            })
      },
      convertDate(dateString, dateOnly=false) {
              const date = new Date(dateString);
              const formattedDate = date.toLocaleDateString();
              const formattedTime = date.toLocaleTimeString();
              if(!dateOnly) {
                  return `<span class="selectable">${formattedDate} à ${formattedTime}</span>`;
              }
              return `${formattedDate}`;
          },
          wizardUpdatedss() {
              console.log(this.campaignByid.planification_date);
              // let newCampaignDateStartF = new Date(this.planification_date);
              // newCampaignDateStartF = addHours(this.planification_date, 3);
              let micro_learning={};
               micro_learning={id:this.micro_learning_id,
                

              };
              console.log(micro_learning);
              getAPI.post(`micro_learning/validate_campaign`, {
                  //name: this.campaignByid.name,
                  campaign_id: this.tempDeleteID,
                  url_shortener_id: this.selectedurlshortener,
                  microlearning_id: this.$route.params.id,
                  test_email: "onesime.adefoulou@gmail.com",
                  is_validated_by_admin: true,
                  
                  
              })
                  .then(response => {
                      if(response.status == 201) {
                           this.newDomainForm3 = false;
                          let msg = 'Campagne validation avec succès.'
                          this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                           this.exitOrgUpdateModal3 = Date.now();
                           this.$swal({
                             title: '',
                             text: 'Campagne validation avec succès. ',
                             type: 'success',
                              showCancelButton: false,
                             confirmButtonText: "D'accord !"
                            });
                            window.location.reload();
                          this.getAllGroup()
                          this.getAllCampaign()
                          
                      }
                      else {
                          
                          let msg = 'Oups ! something went wrong.'
                          this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.exitOrgUpdateModal3 = Date.now();
                    
                      this.$swal({
                          title: '',
                          text: 'Oups ! something went wrong.',
                          type: 'warning',
                          showCancelButton: false,
                          confirmButtonText: "D'accord !"
                      });
                  })
                  .catch(error => {
                      if (error.response) {
                         let msg = 'Oups ! something went wrong.'
                          this.alertString = `${msg}|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          let msg = 'The request was made but no response was received. Please check your network.'
                          this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          let msg = 'Oups ! something went wrong.'
                          this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.exitOrgUpdateModal2 = Date.now();
                      this.newCampaignUpdate = false;
                  });
          
          
      },
          wizardUpdateds() {
              
             
              console.log(this.campaignByid.planification_date);
              // let newCampaignDateStartF = new Date(this.planification_date);
              // newCampaignDateStartF = addHours(this.planification_date, 3);
              let micro_learning={};
               micro_learning={id:this.micro_learning_id,
                

              };
              console.log(micro_learning);
              getAPI.post(`micro_learning/validate_campaign`, {
                  //name: this.campaignByid.name,
                  campaign_id: this.tempDeleteID,
                  microlearning_id: this.$route.params.id,
                  sms_provider_id: this.selectedsmsprovider,
                  url_shortener_id: this.selectedurlshortener, 
                  test_phone_number: '+250780958495',
                  is_validated_by_admin: true,
                  
                  
              })
                  .then(response => {
                      if(response.status == 201) {
                            this.exitOrgUpdateModal2 = Date.now();
                            this.newDomainForm2 = false;
                            let msg = 'Campagne validation avec succès.'
                            this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                             this.$swal({
                             title: '',
                              text: 'Campagne validation avec succès.',
                              type: 'success',
                               showCancelButton: false,
                               confirmButtonText: "D'accord !"
                             });
                              window.location.reload();
                          this.getAllGroup()
                          this.getAllCampaign()
                          
                      }
                      else {
                          
                          let msg = 'Oups ! something went wrong.'
                          this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                     
                      
                  })
                  .catch(error => {
                      if (error.response) {
                          this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          let msg = 'The request was made but no response was received. Please check your network.'
                          this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          let msg = 'Oups ! something went wrong.'
                          this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.exitOrgUpdateModal = Date.now();
                      this.newCampaignUpdate = false;
                  });
          
          
      },
          wizardUpdated() {
              
              const groups = [];
              const service = [];
            //   const serve=["93c1afcb-5838-4ae3-905c-80e6196f4204"];
            //   const group=["3421b42e-55f8-46fb-ae95-32c3a48bc19e"];
              service.push(this.newCampaignService)
              groups.push(this.newCampaignGroupe);
              console.log(this.campaignByid.planification_date);
              // let newCampaignDateStartF = new Date(this.planification_date);
              // newCampaignDateStartF = addHours(this.planification_date, 3);
              let micro_learning={};
               micro_learning={id:this.micro_learning_id,
                bottom_email_message:this.campaignByid.micro_learning.bottom_email_message,
                title:this.campaignByid.micro_learning.title,
                micro_learning_url:this.campaignByid.micro_learning.micro_learning_url,
                sms_message:this.campaignByid.micro_learning.sms_message,
                top_email_message:this.campaignByid.micro_learning.top_email_message,
                im_message:this.campaignByid.micro_learning.im_message,
                duration:this.campaignByid.micro_learning.duration,
                description:this.campaignByid.micro_learning.description,

              };
              console.log(micro_learning);
              getAPI.patch(`micro_learning/campaigns/${this.tempDeleteID}/`, {
                  //name: this.campaignByid.name,
                  micro_learning: micro_learning.id,
                  campaign_type: this.campaignByid.campaign_type,
                  sms_send_name: this.campaignByid.sms_send_name || null,
                  group: this.campaignByid.group || null,
                  email_send_name: this.campaignByid.email_send_name || null,
                  sms_send_signature: this.campaignByid.sms_send_signature || null,
                  email_send_signature: this.campaignByid.email_send_signature || null,
                  planification_date: this.planification_date,
                  default_message:this.campaignByid.default_message,
                  is_validated_by_admin: false,
                  service:this.campaignByid.service || null,
                  status:"available"
                  
              })
                  .then(response => {
                      if(response.status == 200) {
                          this.getAllGroup()
                          this.getAllCampaign()
                          let msg = 'Campagne mise à jour avec succès!'
                          this.campaignWaiting = response.data;
                          console.log("Camp: ", this.campaignWaiting)
                          this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                      }
                      else {
                          
                          let msg = 'Oups ! something went wrong.'
                          this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.exitOrgUpdateModal = Date.now();
                      this.newCampaignUpdate = false;
                      this.$swal({
                          title: 'Campagne créée avec succès',
                          text: 'Campagne créée avec succès. En attente de validation.',
                          type: 'warning',
                          showCancelButton: false,
                          confirmButtonText: "D'accord !"
                      });
                  })
                  .catch(error => {
                      if (error.response) {
                          this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          let msg = 'The request was made but no response was received. Please check your network.'
                          this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          let msg = 'Oups ! something went wrong.'
                          this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.exitOrgUpdateModal = Date.now();
                      this.newCampaignUpdate = false;
                  });
          
          
      },
      
      getAllCampaign () {
              this.waitingAPIResponse = true
              getAPI.get(`micro_learning/campaigns/`)
                  .then(response => {
                      if(response.status == 200) {
                          let groups = response.data.results;
                          let group;
                          
                          for(let i=0; i<groups.length; i++) {
                              let row = groups[i];
                              
                              
                                let  btn = `<div class="d-flex align-items-center">`;
                              btn += `<button style="background-color:white; border: 1px solid #fff;" btn-id="${row.id}" btn-action="edit" class="ms-2 :disabled=" ${row.status == 'success'}"  btn-action"><i class="fa fa-edit " style="color:#90ee90;" ></i></button>`;
                              btn += `${row.campaign_type == 'email' ? `<button style="background-color:white; border: 1px solid #fff;" btn-id="${row.id}" btn-action="validate" class="ms-2 :disabled=" ${row.status == 'success'}"  btn-action"><i class="fa fa-check " style="color:#90ee90;" ></i></button>` :`<button style="background-color:white; border: 1px solid #fff;" btn-id="${row.id}" btn-action="download" @click="callUpdatecampaignFormss(${row.id})" class="ms-2 :disabled="${row.status == 'In Progress'} || ${row.status == 'success'}"  btn-action"><i class="fa fa-check " style="color:#90ee90;" ></i></button>`}`;
                             
                              btn += `<button style="background-color:white; border: 1px solid #fff;" btn-id="${row.id}" btn-action="delete" class="ms-2  btn-action"><i class="fa fa-trash " style="color:#ff0000;"></i></button>`;
                              btn += `</div>`;

                            if(row.service == null){
                              getAPI.get(`groupes/${row.group}/`, {
                    //   name: this.updateGroupPayload.name,
                    //   organisation: sessionStorage.organisation,
                    //   users: this.selectedUser
                  })
                  .then(response => {
                      if(response.status == 200) {
                        //console.log(response.data);
                        group=response.data.name;
                        console.log("hello",group);
                        this.campaignsAll.push({
                                  'Nom de la campaigne':`<span class="selectable">${row.name}</span>`,
                                  'Type':`<span class="selectable">${row.campaign_type}</span>`,
                                  'Statut':`${row.status == "success" ? `<span class="badge rounded-pill badge-danger cfa-bg-secondary mw-status">succès</span>` : (row.status == "canceled" ? `<span class="badge rounded-pill badge-success cfa-bg-success mw-status" style="background-color:##E7F9F1;color:#14C766;">Terminèe</span>` : `<span class="badge rounded-pill badge-warning cfa-bg-warning mw-status" style="background-color:#FEF6EA;color:#F8B13D;">En cours</span>`)}`,
                                  'Date création': this.convertDate(row.created_at, true),

                                   'Date validation': `${row.validated_at == null ? `<span class="badge rounded-pill badge-danger  mw-status" style="background-color:#FEEAEB;color:#FF3947;">En attente</span>`:(row.validated_at != null ? this.convertDate(row.validated_at, true):`hello`)}`,

                                  'Cible':`<span class="selectable">${group}</span>`,
                                  'Actions': btn,
                                  name: row.name,
                                  id: row.id,
                                  created_at: row.created_at
                              });
                              console.log("g",this.campaignsAll);
                              this.campaignsAll.sort((a, b) => {
                              let dateA = new Date(a.created_at);
                               let dateB = new Date(b.created_at);
                             return dateB - dateA; // For descending order (latest first)
                           });
                           
                        //   this.getAllGroup()
                          //let msg = 'Groupe créé avec succès!'
                          //this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                          //this.exitNewGroupModal = Date.now()
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      //this.exitNewGroupModal = Date.now()
                  })}else{
                    getAPI.get(`services/${row.service}/`, {
                    //   name: this.updateGroupPayload.name,
                    //   organisation: sessionStorage.organisation,
                    //   users: this.selectedUser
                  })
                  .then(response => {
                      if(response.status == 200) {
                        //console.log(response.data);
                        group=response.data.name;
                        console.log("hello",group);
                        this.campaignsAll.push({
                                  'Nom de la campaigne':`<span class="selectable">${row.name}</span>`,
                                  'Type':`<span class="selectable">${row.campaign_type}</span>`,
                                  'Statut':`${row.status == "success" ? `<span class="badge rounded-pill badge-danger cfa-bg-secondary mw-status">succès</span>` : (row.status == "failed" ? `<span class="badge rounded-pill badge-success cfa-bg-success mw-status" style="background-color:##E7F9F1;color:#14C766;">Terminèe</span>` : `<span class="badge rounded-pill badge-warning cfa-bg-warning mw-status" style="background-color:#FEF6EA;color:#F8B13D;">En cours</span>`)}`,
                                  'Date création': this.convertDate(row.created_at, true),
                                  'Date validation': `${row.validated_at == null ? `<span class="badge rounded-pill badge-danger  mw-status" style="background-color:#FEEAEB;color:#FF3947;">En attente</span>`:(row.validated_at != null ? this.convertDate(row.validated_at, true):`hello`)}`,
                                  'Cible':`<span class="selectable">${group}</span>`,
                                  'Actions': btn,
                                  name: row.name,
                                  id: row.id,
                                  created_at: row.created_at
                              });
                              console.log("g",this.campaignsAll);
                              this.campaignsAll.sort((a, b) => {
                              let dateA = new Date(a.created_at);
                               let dateB = new Date(b.created_at);
                             return dateB - dateA; // For descending order (latest first)
                           });
                           
                        //   this.getAllGroup()
                          //let msg = 'Groupe créé avec succès!'
                          //this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                          //this.exitNewGroupModal = Date.now()
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      //this.exitNewGroupModal = Date.now()
                  })
                  }
                            //   this.campaignsAll.push({
                            //       'Nom de la campaigne':`<span class="selectable">${row.name}</span>`,
                            //       'Type':`<span class="selectable">${row.campaign_type}</span>`,
                            //       'Statut':`${row.status == "created" ? `<span class="badge rounded-pill badge-danger cfa-bg-secondary mw-status">VALIDATION PENDING</span>` : (row.status == "canceled" ? `<span class="badge rounded-pill badge-success cfa-bg-success mw-status" style="background-color:##E7F9F1;color:#14C766;">Terminèe</span>` : `<span class="badge rounded-pill badge-warning cfa-bg-warning mw-status" style="background-color:#FEF6EA;color:#F8B13D;">En cours</span>`)}`,
                            //       'Date création': this.convertDate(row.created_at, true),
                            //       'Date validation': this.convertDate(row.updated_at, true),
                            //       'Cible':`<span class="selectable">${group}</span>`,
                            //       'Actions': btn,
                            //       name: row.name,
                            //       id: row.id
                            //   });
                          }
                      }
                      else {
                          let msg = 'Oups ! something went wrong.'
                          this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      setTimeout(() => {
                          this.waitingAPIResponse = false
                      }, 2000);
                  })
                  .catch(error => {
                      if (error.response) {
                          this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          let msg = 'The request was made but no response was received. Please check your network.'
                          this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          let msg = 'Oups ! something went wrong.'
                          this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.waitingAPIResponse = false
                  })
                
          },
      onImageLoaded (imageData) {
        this.module_image_cover = imageData
      },
      joinStringToArray (s1, s2, s3) {
        let array = []
        if(s1 != "")
          array.push(`${s1}`)
        if(s2 != "")
          array.push(`${s2}`)
        if(s3 != "")
          array.push(`${s3}`)
        return array;
      },
      addNewMicroLearning () {
       // this.waitingAPIResponse =true;
            console.log(this.module_image_cover);
            const formData = new FormData();
              formData.append('cover_image', this.module_image_cover);
          getAPI.post('micro_learning/courses/', {
            title: this.module_title,
            description: this.module_description,
            micro_learning_url: this.module_microlearning_url,
            top_email_message: this.module_top_message,
            bottom_email_message: this.module_bottom_message,
            sms_message: this.module_sms_message,
            im_message: this.module_im_message,
            duration: this.module_time,
            
            
          }).then((response) => {
            if(response.status == 201) {
                const formData = new FormData();
            formData.append('cover_image', this.module_image_cover);
            getAPI.patch(`micro_learning/courses/${response.data.id}/`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then(() => {
              this.getAllModule()
              let msg = 'Micro learning ajouté avec succès!'
              this.alertString = `${msg}|alert-yes|5000::`+Date.now()
            })
          }
          this.exitModuleModal = Date.now()
         this. waitingAPIResponse=true;
          }).catch((error) => {
            if(error.response.status == 413) {
              this.alertString = `Oops ! Uploaded File Too Large|alert-no|10000::`+Date.now()
            }
            if (error.response) {
              let firstError = Object.values(error.response.data)[0][0].replace(' en ', " ")
              this.alertString = `${firstError}|alert-no|10000::`+Date.now()
            }
            else if (error.request) {
              let msg = 'The request was made but no response was received. Please check your network.'
              this.alertString = `${msg}|alert-no|8000::`+Date.now()
            }
            else {
              let msg = 'Oups ! something went wrong.'
              this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            this.exitModuleModal = Date.now()
          });
        },
    },
    computed: {
          
          nextButtonOptionss() {
              return (this.currentTabIndex === 33)
                  ? {
                      text: 'Créer la campagne',
                      icon: 'check',
                      hideIcon: true, // default false but selected for sample
                      hideText: false, // default false but selected for sample
                      disabled: false,
                  }
                  : ((this.currentTabIndex === 0 && (this.campaignByid.name == null || this.campaignByid.campaign_type === 'default' )) ?
                      {
                          disabled: true,
                          text: "Suivant",
                      }
                      : ((this.currentTabIndex === 1 && (this.newCampaignType == null)) ?
                          {
                              disabled: true,
                              text: "Suivant",
                          }
                          : ((this.currentTabIndex === 2 && (this.newCampaignTarget == null)) ?
                              {
                                  disabled: true,
                                  text: "Suivant",
                              }
                              : ((this.currentTabIndex === 3 && (this.newCampaignTarget == null)) ?
                                  {
                                      disabled: true,
                                      text: "Suivant",
                                  }
                                  : 
                                  {
                                          disabled: false,
                                          text: "Suivant",
                                  })
                              )
                          )
                      );
          },
          
          prevButtonOptionss() {
              return {
                  disabled: false,
                  text: "Précédent"
              };
          },
          doneButtonOptions() {
              return (this.newCampaignTarget == 'default') ? {
                  disabled: true,
                  text: "Terminé"
              }: {
                  disabled: false,
                  text: "Terminé"
              };
          },
      },
   
    mounted () {
      this.getAllModule(),
      this.getAllTabs()
      this.getAllCampaign();
    }
  }
  </script>
  
  <style scoped>
  .tagUl{
    padding: 0;
  }
  .tagUl, .tagUl li{
    list-style-type: none;
  }
  .tag-bloc{
    min-height: 18em;
    width: calc(100% - 4em);
    background-color: red;
    /*background-color: #272f44;*/
    position: fixed;
    left: 2em;
    right: 2em;
    bottom: -14.5em;
  }
  /* TAB STYLE */
.tab-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.tab-header ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}
.tab-header ul li {
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #333;
    border: 1px solid #ccc;
    border-bottom: none;
    border-radius: 5px 5px 0 0;
}
.tab-header ul li.active {
    background-color: #333;
    color: #fff;
}
.tab-body {
    border: 1px solid #ccc;
    border-radius: 0 .5em .5em .5em;
    min-height: 10em;
}
  .descriptionArea{
    height: 135px;
    width: 100%;
    position: relative;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 600;
    outline: currentcolor none medium !important;
    border: 1px solid rgb(204, 204, 204);
    box-shadow: rgba(0, 0, 0, 0.075) 0px 1px 1px inset;
    padding: 6px 12px;
    background: rgb(247, 248, 252) none repeat scroll 0% 0%;
  }
  .uploadImageArea{
    width: 100%;
    height: 20em;
    background-color: #d7dde3;
  }
  </style>
  