import { createRouter, createWebHistory } from 'vue-router'
import DashboardView from '../views/private/DashboardView.vue'
import ModuleView from '../views/private/ModuleView.vue'
import ModulePreView from '../views/private/ModulePreView.vue'
import ResourceView from '../views/private/ResourceView.vue'
import SampleView from '../views/private/SampleView.vue'
import PlanView from '../views/private/PlanView.vue'
import FeaturePlanView from '../views/private/FeaturePlanView.vue'
import RDVView from '../views/private/RDVView.vue'
import OrganisationView from '../views/private/OrganisationView.vue'
import OrganisationPreview from '../views/private/OrganisationPreview.vue'
import QuizView from '../views/private/QuizView.vue'
import RoleView from '../views/private/RoleView.vue'
import CompanyView from '../views/private/CompanyView.vue'
import UserProgressionView from '../views/private/UserProgressionView.vue'
import UserManagmentView from '../views/private/UserManagmentView.vue'
import PhishingView from "../views/private/PhishingView.vue"
import SmishingView from "../views/private/SmishingView.vue"
import smishingDetailsView from"../views/private/SmishingPreview.vue"
import ParameterView from "../views/private/ParameterView.vue"
import FacturationView from "../views/private/FacturationView.vue"
import WebContainerView from "../views/private/WebContainerView.vue"
import PdfContainerView from "../views/private/PdfContainerView.vue"
import Login2View from '../views/public/auth/Login2View.vue'
import PasswordForgetView from '../views/public/auth/PasswordForgetView.vue'
import PasswordResetView from '../views/public/auth/PasswordResetView.vue'
import phishingDetailsView from "@/views/private/PhishingDetailsView.vue";
import MicroLearningDetails from "../views/private/Micro-learningPreView.vue";
import MicroCampaign from "../views/private/microlearningCampaigns.vue";
import ActivityView from "../views/private/ActivityView.vue";
import MicroLearning from "../views/private/Micro-learning.vue";
import { i18n } from '../main'
import PubView from "@/views/private/PubView.vue";
import NewsletterView from "@/views/private/NewsletterView.vue";
import SupportView from "@/views/private/SupportView.vue";
import SupportDetailView from "@/views/private/SupportDetailView.vue";
import OrganisationBackupView from "../views/private/OrganisationBackup.vue";
import AuditlogsView from "../views/private/AuditLogs.vue";
import BackgroundTask from "../views/private/BackgroundTask.vue";
import UrlsProvidersView from '@/views/private/UrlsProvidersView.vue'

const ifAuthenticated = (to, from, next) => {
  if(sessionStorage.getItem('token') != null) {
    next()
    return;
  }
  next('/?loginRequired=true')
}

const notAuthenticated = (to, from, next) => {
  if(sessionStorage.getItem('token') == null) {
    next()
    return;
  }
  next('/management-board')
}

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login2View,
    beforeEnter: notAuthenticated
  },
  {
    path: '/password-forget',
    name: 'PasswordForget',
    component: PasswordForgetView,
    beforeEnter: notAuthenticated
  },
  {
    path: '/password-reset',
    name: 'PasswordReset',
    component: PasswordResetView,
    beforeEnter: notAuthenticated
  },
  // {
  //   path: '/Organisationbackup',
  //   name: 'OrganisationBackup',
  //   component: OrganisationBackupView,
  //   beforeEnter: notAuthenticated
  // },
  {
    path: '/management-board',
    name: 'Dashboard',
    component: DashboardView,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/management-board/modules',
    name: 'Module',
    component: ModuleView,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/management-board/task',
    name: 'Tasks',
    component: BackgroundTask,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/management-board/activity',
    name: 'Activity',
    component: ActivityView,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/management-board/micro-learning',
    name: 'Micro-Learning',
    component: MicroLearning,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/management-board/audit-logs',
    name: 'Audit-Logs',
    component: AuditlogsView,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/management-board/micro-learning-details/:module/:id',
    name: 'Micro-Learning-details',
    component: MicroLearningDetails,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/management-board/modules-details/:module/:id',
    name: 'ModulePreview',
    component: ModulePreView,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/modules/courseroom/:id/:slug/',
    name: 'ModuleIframe',
    component: WebContainerView,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/ressources',
    name: 'Resources',
    component: ResourceView,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/ressources/content/:id/:slug/',
    name: 'ResourcesIframe',
    component: PdfContainerView,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/management-board/organisation',
    name: 'Organisation',
    component: OrganisationView,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/management-board/organisation-backup',
    name: 'Organisation-backup',
    component: OrganisationBackupView,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/management-board/Micro-campaign',
    name: 'MicroLearningCampaign',
    component: MicroCampaign,
    beforeEnter: ifAuthenticated
  },
 
  {
    path: '/management-board/quiz-final',
    name: 'Quiz',
    component: QuizView,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/iam-role-permissions',
    name: 'Role',
    component: RoleView,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/management-board/plan',
    name: 'Plan',
    component: PlanView,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/management-board/featureplan',
    name: 'FeaturePlan',
    component: FeaturePlanView,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/management-board/rendez-vous-expert',
    name: 'RDV',
    component: RDVView,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/entreprise',
    name: 'Entreprise',
    component: CompanyView,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/management-board/suivi-utilisateurs',
    name: 'UserProgress',
    component: UserProgressionView,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/management-board/gestion-utilisateurs',
    name: 'UserManage',
    component: UserManagmentView,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/management-board/parametres',
    name: 'Setting',
    component: ParameterView,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/facturation',
    name: 'Facturation',
    component: FacturationView,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/support',
    name: 'Support',
    component: SupportView,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/support-content/:id',
    name: 'SupportDetail',
    component: SupportDetailView,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/management-board/campagne-de-phishing',
    name: 'Phishing',
    component: PhishingView,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/management-board/campagne-de-smishing',
    name: 'Smishing',
    component: SmishingView,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/management-board/urls-providers',
    name: 'UrlsProviders',
    component: UrlsProvidersView,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/logout',
    name: 'Logout',
    component: SampleView,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/errors/404.vue'),
  },
  {
    path: '/management-board/pub-space',
    name: 'EspacePub',
    component: PubView,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/management-board/newsletter',
    name: 'Newsletter',
    component: NewsletterView,
    beforeEnter: ifAuthenticated
  },
  {
    path: "/campagne-de-phishing/details/:id",
    name: "PhishingDetails",
    component: phishingDetailsView,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/organisatioon/details/:id",
    name: "organisationDetails",
    component: OrganisationPreview,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/campagne-de-smishing/details/:id",
    name: "SmishingDetails",
    component: smishingDetailsView,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/management-board/subscription',
    name: 'Subscription',
    component: FacturationView,
    beforeEnter: ifAuthenticated
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: "cfa-link-active",
  linkExactActiveClass: "cfa-link-exact-active",
  routes
})

router.beforeEach((to, from, next) => {
  i18n.locale = sessionStorage.getItem('language') || 'fr'
  if (!to.matched.length) {
    next('/404');
  }
  else {
    next();
  }
  return next()
});

export default router
