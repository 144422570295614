<template>
    <div class="cfa-root">
        <c-f-a-nav  :alert="alertString">
            <template v-slot:title>
                <div class="w-100 pt-5 text-start mt-2 mb-3">
                    <h1 class="cfa-section-title">{{$t('list_of_micro_awareness')}}</h1>
                </div>
            </template>
            <template v-slot:content>
                <div v-if="isConnected">
                    <div v-if="!waitingAPIResponse" class="cfa-page">
                        <div class="tab mt-4">
                            <div class="tab-header">
                                <ul class="tab-ul">
                                    
                                    <li :class="{ 'active': currentTab === 'campaign_activate' }" @click="currentTab = 'campaign_activate'" class="d-md-flex d-none"> Campagnes&nbsp;en attente de validation ({{validationWaiting}}) </li>
                                    <li :class="{ 'active': currentTab === 'campaign_archived2' }" @click="currentTab = 'campaign_archived2'" class="d-md-flex d-none"> Campagnes en cours ({{inProgress}}) </li>

                                    <li :class="{ 'active': currentTab === 'campaign_archived' }" @click="currentTab = 'campaign_archived'" class="d-md-flex d-none"> Campagnes terminées ({{archived}})</li>

                                   
                                    <!-- <li :class="{ 'active': currentTab === 'groups' }" @click="currentTab = 'groups'" class="d-md-none d-flex"> Groupes (1)</li> -->
                                </ul>
                                <div class="d-flex align-items-center justify-content-end p-1">
                                    <button v-if="currentTab === 'declaration'" @click="newTemplate" class="btn cfa-btn-primary d-flex align-items-center" style="height: 2.7em;">
                                        <span class="d-md-flex d-none">NOUVEAU&nbsp;</span>
                                        <span>DECLARATION</span>&nbsp;<i class="fa fa-plus">&nbsp;</i>
                                    </button>
                                    <!-- <button v-if="currentTab === 'landing'" @click="newLanding" class="btn cfa-btn-primary d-flex align-items-center" style="height: 2.7em;">
                                        <span class="d-md-flex d-none">NOUVEAU&nbsp;</span>
                                        <span>LANDING</span>&nbsp;<i class="fa fa-plus">&nbsp;</i>
                                    </button> -->
                                    <!-- <button v-if="currentTab === 'campaign_activate_'" @click="newCampaign" class="btn cfa-btn-primary d-flex align-items-center" style="height: 2.7em;">
                                        <span class="d-md-flex d-none">NOUVELLE&nbsp;</span>
                                        <span>CAMPAGNE</span>&nbsp;<i class="fa fa-plus">&nbsp;</i>
                                    </button> -->
                                    <!-- <button v-if="currentTab === 'groups'" @click="newGroup" class="btn cfa-btn-primary d-flex align-items-center" style="height: 2.7em;">
                                        <span class="d-md-flex d-none">NOUVEAU&nbsp;</span>
                                        <span>GROUPE</span>&nbsp;<i class="fa fa-plus">&nbsp;</i>
                                    </button> -->
                                    <!-- <button v-if="currentTab === 'smtp'" @click="newSmtp" class="btn cfa-btn-primary d-flex align-items-center" style="height: 2.7em;">
                                        <span class="d-md-flex d-none">NOUVEAU&nbsp;</span>
                                        <span>SMTP</span>&nbsp;<i class="fa fa-plus">&nbsp;</i>
                                    </button> -->
                                </div>
                            </div>
                            <div class="tab-body">
                                
                                
                                
                                <div v-if="currentTab === 'campaign_activate'">
                                    <Table  :columns="campaignsAllColumns" :data="campaignsAll" :columns-action="campaignsAllToListen" @action="getCallToActionForCampaigns" :customized-default-image="true" >
                                        <template v-slot:defaultImage>
                                            <img src="@/assets/img/gif/campagne.gif" alt="empty content" style="width: 11em;"/>
                                            <span class="mt-3 fs-5 fw-bold pl-2 text-center empty-label">Aucune campagne !</span>
                                        </template>
                                    </Table>
                                </div>
                                <div v-if="currentTab === 'campaign_archived2'">
                                    <Table  :columns="campaignsAllColumns" :data="campaignsAll2" :columns-action="campaignsAllToListen" @action="getCallToActionForCampaigns" :customized-default-image="true" >
                                        <template v-slot:defaultImage>
                                            <img src="@/assets/img/gif/archives.gif" alt="empty content" style="width: 11em;"/>
                                            <span class="mt-3 fs-5 fw-bold pl-2 text-center empty-label">Aucune campagne !</span>
                                        </template>
                                    </Table>
                                </div>
                                <div v-if="currentTab === 'campaign_archived'">
                                    <Table  :columns="campaignsAllColumns" :data="campaignsAll3" :columns-action="campaignsAllToListen" @action="getCallToActionForCampaigns" :customized-default-image="true" >
                                        <template v-slot:defaultImage>
                                            <img src="@/assets/img/gif/archives.gif" alt="empty content" style="width: 11em;"/>
                                            <span class="mt-3 fs-5 fw-bold pl-2 text-center empty-label">Aucune campagne !</span>
                                        </template>
                                    </Table>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div v-if="waitingAPIResponse" class="cfa-page">
                        <div class="d-flex justify-content-center align-items-center" style="min-height: 70vh">
                            <div class="preloader_center d-flex flex-column">
                                <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
                                <p class="pl-3 fw-bold">Loading...</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="cfa-page">
                    <div class="d-flex justify-content-center align-items-center" style="min-height: 70vh">
                        <div class="preloader_center d-flex flex-column">
                            <img src="../../assets/img/gif/loading.gif" alt="cfa preloader" class="mb-2">
                            <p class="pl-3 fw-bold">Initializing phishing instance...</p>
                        </div>
                    </div>
                </div>
                <!-- Modal zone-->
                <!-- deletion -->
                <scalable-modal @callback="confirmDeletion1" :break="exitOrgModalDel" :notifyTaskDone="callbackPayload" :uid="'phish-general-deletion-modal'" @close="openDeletionBox=false" :open="openDeletionBox" :advancedControl="true" validationButtonText="Supprimer" exitButtonText="Annuler" displayMode="flex" :style="'width: 30em; height: 22em;'">
                    <template v-slot:content>
                        <p>Etes vous sûr de vouloir supprimer ce groupe? <span style="color: red;">Cette Action est irreversible</span></p>
                        <div class="col-12 pl-0">
                            <div class="form-group py-3 pe-0 pe-md-2">
                                <p class="mb-2"><strong>Entrez l'intitulé du groupe pour confirmation</strong></p>
                                <input class="form-control" type="texte" autocomplete="new-password" v-model="name_check">
                            </div>
                        </div>
                    </template>
                </scalable-modal>
                <scalable-modal @callback="confirmDeletion2" :break="exitOrgModalDel" :notifyTaskDone="callbackPayload" :uid="'phish-campaign-deletion-modal'" @close="openDeletionCampaignBox=false" :open="openDeletionCampaignBox" :advancedControl="true" validationButtonText="Supprimer" exitButtonText="Annuler" displayMode="flex" :style="'width: 30em; height: 22em;'">
                    <template v-slot:content>
                        <p>Etes vous sûr de vouloir supprimer cette campagne? <span style="color: red;">Cette Action est irreversible</span></p>
                        <div class="col-12 pl-0">
                            <div class="form-group py-3 pe-0 pe-md-2">
                                <p class="mb-2"><strong>Entrez l'intitulé de la campagne pour confirmation</strong></p>
                                <input class="form-control" type="texte" autocomplete="new-password" v-model="name_check">
                            </div>
                        </div>
                    </template>
                </scalable-modal>

                <scalable-modal @callback="confirmDeletionLanding" :break="exitOrgModalDel" :notifyTaskDone="callbackPayload" :uid="'landing-deletion-modal'" @close="openDeletionCampaignBox3=false" :open="openDeletionCampaignBox3" :advancedControl="true" validationButtonText="Supprimer" exitButtonText="Annuler" displayMode="flex" :style="'width: 30em; height: 22em;'">
                    <template v-slot:content>
                        <p>Etes vous sûr de vouloir supprimer ce landing page ? <span style="color: red;">Cette Action est irreversible</span></p>
                    </template>
                </scalable-modal>

                <scalable-modal @callback="confirmDeletionSmtp" :break="exitOrgModalDel" :notifyTaskDone="callbackPayload" :uid="'smtp-deletion-modal'" @close="openDeletionCampaignBox2=false" :open="openDeletionCampaignBox2" :advancedControl="true" validationButtonText="Supprimer" exitButtonText="Annuler" displayMode="flex" :style="'width: 30em; height: 22em;'">
                    <template v-slot:content>
                        <p>Etes vous sûr de vouloir supprimer ce smtp ? <span style="color: red;">Cette Action est irreversible</span></p>
                    </template>
                </scalable-modal>
<!--create new group-->
<scalable-modal @callback="confirmCreates" :break="exitOrgUpdateModal" label="CRÉER UN PLAN" uid="cfa-modal-phish-group-create" @action="getCallToActionForUserGroups" @close="openCreateGroup=false" :open="openCreateGroup"  :advancedControl="true" validationButtonText="Enregistrer les modifications" exitButtonText="Annuler" displayMode="block" :style="'width: 60em; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
                    <template v-slot:content>
                        <!-- blo1 -->
                        <div class="row w-100">
                            <div class="form-group py-2 pe-0 pe-md-2 ">
                                <p class="mb-2"><strong>Nom</strong></p>
                                <div class="d-flex flex-row">
                                    <input class="form-control" type="text" v-model="name">
                                </div>
                            </div>
                        </div>
                       <div class="row w-100">
                        <div class="form-group py-2 pe-0 pe-md-2">
                            <p class="mb-2"><strong>Organisation</strong></p>
                            <div class="d-flex flex-row">
                               <select class="form-control " v-model="organisation">
              
                                    <option disabled value="null">--</option>
            
                                   <option v-for="service  in allOrganisation" :key="service.id" :value="service.id">{{ service.name }}</option>
                                   </select> 
                                
                            </div>
                        </div>
                       </div>
                        <div style="background: #e6e6e6; margin: 1em; border-radius: 5px; width: calc(100% - 1.4em);">
                            <div class="w-100 d-flex flex-row justify-content-start align-items-center px-2 mt-4">
                                <button class="form-control btn cfa-btn-primary ml-2 w-auto">IMPORTER DES UTILISATEURS <i class="fa fa-plus"></i></button> <span>(au format .csv)</span>
                            </div>
                            <div class="w-100 px-2">
                                <div class="form-group py-2 pe-0 pe-md-2 ">
                                    <p class="mb-2"><strong>Ajouter un utilisateur</strong></p>
                                    <div class="d-flex flex-row">
                                        <input class="form-control mr-1" type="text" placeholder="Nom" v-model="newFirstname">
                                        <input class="form-control mx-1" type="text" placeholder="Prénom" v-model="newLastname">
                                        <input class="form-control mx-1" type="text" placeholder="Email" v-model="newEmail">
                                        <input class="form-control mx-1" type="text" placeholder="Position" v-model="newPosition">
                                        <button  @click="addUsers" class="form-control btn cfa-btn-primary ms-1"><i class="fa fa-plus"></i>AJOUTÉ </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row w-100 p-1 mt-4">
                            <TableMin :i="4" min :columns="userGroupAllColumns" :data="userGroupAll" :columns-action="userGroupAllToListen" @action="getCallToActionForUserGroup"  :hide-control="true"></TableMin>
                        </div>
                    </template>
                </scalable-modal>
                <!-- update -->
                <scalable-modal @callback="confirmUpdate" :break="exitOrgUpdateModal" label="CRÉER UN PLAN" uid="cfa-modal-phish-group-update" @close="openUpdateGroup=false" :open="openUpdateGroup"  :advancedControl="true" validationButtonText="Enregistrer les modifications" exitButtonText="Annuler" displayMode="block" :style="'width: 60em; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
                    <template v-slot:content>
                        <!-- blo1 -->
                        <div class="row w-100">
                            <div class="form-group py-2 pe-0 pe-md-2 ">
                                <p class="mb-2"><strong>Nom</strong></p>
                                <div class="d-flex flex-row">
                                    <input class="form-control" type="text" v-model="selectedGroup.name">
                                </div>
                            </div>
                        </div>
                        <div class="row w-100">
                        <div class="form-group py-2 pe-0 pe-md-2">
                            <p class="mb-2"><strong>Organisation</strong></p>
                            <div class="d-flex flex-row">
                               <select class="form-control " v-model="selectedGroup.organisation">
              
                                    <option disabled value="null">--</option>
            
                                   <option v-for="service  in allOrganisation" :key="service.id" :value="service.id">{{ service.name }}</option>
                                   </select> 
                                
                            </div>
                        </div>
                       </div>
                        <div style="background: #e6e6e6; margin: 1em; border-radius: 5px; width: calc(100% - 1.4em);">
                            <div class="w-100 d-flex flex-row justify-content-start align-items-center px-2 mt-4">
                                <button class="form-control btn cfa-btn-primary ml-2 w-auto">IMPORTER DES UTILISATEURS <i class="fa fa-plus"></i></button> <span>(au format .csv)</span>
                            </div>
                            <div class="w-100 px-2">
                                <div class="form-group py-2 pe-0 pe-md-2 ">
                                    <p class="mb-2"><strong>Ajouter un utilisateur</strong></p>
                                    <form class="d-flex flex-row">
                                        <input class="form-control mr-1" type="text" placeholder="Nom" v-model="newFirstname">
                                        <input class="form-control mx-1" type="text" placeholder="Prénom" v-model="newLastname">
                                        <input class="form-control mx-1" type="text" placeholder="Email" v-model="newEmail">
                                        <input class="form-control mx-1" type="text" placeholder="Position" v-model="newPosition">
                                        <button class="form-control btn cfa-btn-primary ms-1">AJOUTÉ <i class="fa fa-plus"></i></button>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div class="row w-100 p-1 mt-4">
                            <TableMin :i="4" min :columns="userGroupAllColumns" :data="userGroupAll" :columns-action="userGroupAllToListen" @action="getCallToActionForUserGroup"  :hide-control="true"></TableMin>
                        </div>
                    </template>
                </scalable-modal>

                <!-- create -->
                <scalable-modal @callback="confirmCreate" :break="exitOrgUpdateModal" label="CRÉER UN PLAN" uid="cfa-modal-phish-group-create" @close="newCampaignForm=false" :open="newCampaignForm"  :advancedControl="true" validationButtonText="Nouvelle campagne" exitButtonText="Annuler" displayMode="flex" :style="'width: 60em; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
                    <template v-slot:content>
                        <!-- blo1 -->
                        <div class="row w-100">
                            <div class="form-group py-2 pe-0 pe-md-2 ">
                                <p class="mb-2"><strong>Nom</strong></p>
                                <div class="d-flex flex-row">
                                    <input class="form-control" type="text" v-model="newCampaignName">
                                </div>
                            </div>
                        </div>

                        <div class="row w-100">
                            <div class="form-group py-2 pe-0 pe-md-2 ">
                                <p class="mb-2"><strong>Groupes cibles</strong></p>
                                <div class="d-flex flex-row">
                                    <input class="form-control" type="text" v-model="newCampaignName">
                                </div>
                            </div>
                        </div>

                        <div class="row w-100">
                            <div class="form-group py-2 pe-0 pe-md-2 ">
                                <p class="mb-2"><strong>Date de début</strong></p>
                                <div class="d-flex flex-row">
                                    <input class="form-control" type="text" v-model="newCampaignName">
                                </div>
                            </div>
                        </div>

                        <div class="row w-100">
                            <div class="form-group py-2 pe-0 pe-md-2 ">
                                <p class="mb-2"><strong>Date de fin</strong></p>
                                <div class="d-flex flex-row">
                                    <input class="form-control" type="text" v-model="newCampaignName">
                                </div>
                            </div>
                        </div>

                        <div class="row w-100">
                            <div class="form-group py-2 pe-0 pe-md-2 ">
                                <p class="mb-2"><strong>Type</strong></p>
                                <div class="d-flex flex-row">
                                    <select class="form-control" v-model="newCampaignType">
                                        <option value="default">Select an option</option>
                                        <option value="SMSHING">SMSHING</option>
                                        <option value="PHSISHING">PHSISHING</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="row w-100">
                            <div class="form-group py-2 pe-0 pe-md-2 ">
                                <p class="mb-2"><strong>Template Email</strong></p>
                                <div class="d-flex flex-row">
                                    <input class="form-control" type="text" v-model="newCampaignName">
                                </div>
                            </div>
                        </div>

                        <div class="row w-100">
                            <div class="form-group py-2 pe-0 pe-md-2 ">
                                <p class="mb-2"><strong>Template Landing</strong></p>
                                <div class="d-flex flex-row">
                                    <input class="form-control" type="text" v-model="newCampaignName">
                                </div>
                            </div>
                        </div>

                    </template>
                </scalable-modal>
                <scalable-modal @callback="confirmCreateTemplate" :break="exitOrgUpdateModal2" label="CRÉER UN PLAN" uid="cfa-modal-phish-template-create" @close="newTemplateForm=false" :open="newTemplateForm"  :advancedControl="true" validationButtonText="Nouveau declaration" exitButtonText="Annuler" displayMode="flex" :style="'width: 60em; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
                    <template v-slot:content>
                        <!-- blo1 -->
                        <div class="row w-100">
                            <div class="form-group py-2 pe-0 pe-md-2 ">
                                <p class="mb-2"><strong>Template</strong></p>
                                <div class="d-flex flex-row">
                                    <select class="form-control" v-model="selectedTemplate" >
  <option selected disabled value="">Sélectionnez un modèle</option>
  <option v-for="declare in templatesData" :value="declare.id" v-bind:key="declare.name">{{declare.name}}</option>
</select>
                                </div>
                            </div>
                        </div>
                        <div class="row w-100">
                            <div class="form-group py-2 pe-0 pe-md-2 ">
                                <p class="mb-2"><strong>Landing</strong></p>
                                <div class="d-flex flex-row">
                                    <select class="form-control" v-model="selectedLanding" >
  <option selected disabled value="">Sélectionnez l'atterrissage</option>
  <option v-for="declare in LandingsData" :value="declare.id" v-bind:key="declare.name">{{declare.name}}</option>
</select>
                                </div>
                            </div>
                        </div>
                        
                        
                        
                        
                        
                        
                        
                    </template>
                </scalable-modal>

                <scalable-modal @callback="confirmUpdateTemplate" :break="exitOrgUpdateModal3" label="CRÉER UN PLAN" uid="cfa-modal-phish-template-update" @close="newTemplateForm2=false" :open="newTemplateForm2"  :advancedControl="true" validationButtonText="Modifier" exitButtonText="Annuler" displayMode="flex" :style="'width: 60em; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
                    <template v-slot:content>
                        <!-- blo1 -->
                        <div class="row w-100">
                            <div class="form-group py-2 pe-0 pe-md-2 ">
                                <p class="mb-2"><strong>Template</strong></p>
                                <div class="d-flex flex-row">
                                    <select class="form-control" v-model="declarationData.template_id
" >
  <option selected disabled value="">Sélectionnez un modèle</option>
  <option v-for="declare in templatesData" :value="declare.id" v-bind:key="declare.name">{{declare.name}}</option>
</select>
                                </div>
                            </div>
                        </div>
                        <div class="row w-100">
                            <div class="form-group py-2 pe-0 pe-md-2 ">
                                <p class="mb-2"><strong>Landing</strong></p>
                                <div class="d-flex flex-row">
                                    <select class="form-control" v-model="declarationData.landing_page_id" >
  <option selected disabled value="">Sélectionnez l'atterrissage</option>
  <option v-for="declare in LandingsData" :value="declare.id" v-bind:key="declare.name">{{declare.name}}</option>
</select>
                                </div>
                            </div>
                        </div>
                        
                       
                        
                        
                    </template>
                </scalable-modal>

                <!--
                <scalable-modal @callback="deleteTemplate" uid="cfa-modal-002" @close="openDeletionBox2=false" :open="openDeletionBox2" :advancedControl="true" validationButtonText="Supprimer" exitButtonText="Annuler" :style="'width: 30em; height: 22em;'">
                  <template v-slot:content>
                    <p>Etes vous sûr de vouloir supprimer ce template? <span style="color: red;">Cette Action est irreversible</span></p>
                  </template>
                </scalable-modal>
                -->

                <scalable-modal @callback="deleteTemplate" :break="exitOrgModalDel" :notifyTaskDone="callbackPayload" :uid="'phish-general-deletion-modal2'" @close="openDeletionBox2=false" :open="openDeletionBox2" :advancedControl="true" validationButtonText="Supprimer" exitButtonText="Annuler" displayMode="flex" :style="'width: 30em; height: 22em;'">
                    <template v-slot:content>
                        <p>Etes vous sûr de vouloir supprimer ce declaration? <span style="color: red;">Cette Action est irreversible</span></p>
                    </template>
                </scalable-modal>

                <scalable-modal @callback="confirmCreateLanding" :break="exitOrgUpdateModal" label="CRÉER UN PLAN" uid="cfa-modal-phish-landing-create" @close="newLandingForm=false" :open="newLandingForm"  :advancedControl="true" validationButtonText="Nouveau Landing Page" exitButtonText="Annuler" displayMode="flex" :style="'width: 60em; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
                    <template v-slot:content>
                        <!-- blo1 -->
                        <div class="row w-100">
                            <div class="form-group py-2 pe-0 pe-md-2 ">
                                <p class="mb-2"><strong>Name</strong></p>
                                <div class="d-flex flex-row">
                                    <input class="form-control" type="text" v-model="newLandingName">
                                </div>
                            </div>
                        </div>
                        <div class="row w-100">
                            <div class="form-group py-2 pe-0 pe-md-2 ">
                                <p class="mb-2"><strong>Redirect URL</strong></p>
                                <div class="d-flex flex-row">
                                    <input class="form-control" type="text" v-model="newLandingUrl">
                                </div>
                            </div>
                        </div>
                        <div class="row w-100">
                            <div class="form-group py-2 pe-0 pe-md-2 ">
                                <div class="-d-flex align-items-center justify-content-between">
                                    <p class="mb-2"><strong>HTML</strong></p>
                                </div>
                                <div class="d-flex flex-row">
                                    <vue-editor v-model="newLandingHtmlCode"/> <!-- htmlForEditor -->
                                    <!--<textarea style="min-height: 19em" class="form-control" type="text" v-model="newLandingHtmlCode"></textarea>-->
                                </div>
                            </div>
                        </div>
                        <div class="row w-100">
                            <div class="form-group py-2 pe-0 pe-md-2 ">
                                <label class="d-flex align-items-center cursor-pointer">
                                    <input v-model="newLandingCapturePrivacy" id="customCheckLogin" type="checkbox" class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150" style="margin-right: .5em;" @click="updatePrivacyCheck" />
                                    <span class="ml-2 text-sm font-semibold text-blueGray-600">
                                    Capturer les données soumises
                                    </span>
                                </label>
                            </div>
                        </div>
                        <div class="row w-100">
                            <div class="form-group py-2 pe-0 pe-md-1 ">
                                <label class="d-flex align-items-center cursor-pointer">
                                    <input v-model="newLandingCapturePass" id="customCheckLogin" type="checkbox" class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150" style="margin-right: .5em;" @click="updatePassCheck" />
                                    <span class="ml-2 text-sm font-semibold text-blueGray-600" :style="{ color: 'black', border: border }">
                                    Capturer les mots de passe
                                    </span>
                                </label>
                                <p class="mb-2 case"><strong><i class="fa fa-question"></i> Warning: </strong>Credentials re currently <strong>not encrypted.</strong> This means that captured passwords ar stored in the database as cleartext. Be careful with this. </p>
                            </div>
                        </div>
                    </template>
                </scalable-modal>

                <scalable-modal @callback="confirmUpdateLanding" :break="exitOrgUpdateModal" label="CRÉER UN PLAN" uid="cfa-modal-phish-landing-update" @close="newLandingForm2=false" :open="newLandingForm2"  :advancedControl="true" validationButtonText="Modifier" exitButtonText="Annuler" displayMode="flex" :style="'width: 60em; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
                    <template v-slot:content>
                        <!-- blo1 -->
                        <div class="row w-100">
                            <div class="form-group py-2 pe-0 pe-md-2 ">
                                <p class="mb-2"><strong>Name</strong></p>
                                <div class="d-flex flex-row">
                                    <input class="form-control" type="text" v-model="landingNew.name">
                                </div>
                            </div>
                        </div>
                        <div class="row w-100">
                            <div class="form-group py-2 pe-0 pe-md-2 ">
                                <p class="mb-2"><strong>Redirect URL</strong></p>
                                <div class="d-flex flex-row">
                                    <input class="form-control" type="text" v-model="landingNew.url">
                                </div>
                            </div>
                        </div>
                        <div class="row w-100">
                            <div class="form-group py-2 pe-0 pe-md-2 ">
                                <div class="-d-flex align-items-center justify-content-between">
                                    <p class="mb-2"><strong>HTML</strong></p>
                                </div>
                                <div class="d-flex flex-row">
                                    <vue-editor v-model="landingNew.html_code"/> <!-- htmlForEditor -->
                                    <!--<textarea style="min-height: 19em" class="form-control" type="text" v-model="landingNew.html_code"></textarea>-->
                                </div>
                            </div>
                        </div>
                        <div class="row w-100">
                            <div class="form-group py-2 pe-0 pe-md-2 ">
                                <label class="d-flex align-items-center cursor-pointer">
                                    <input v-model="landingNew.captureCredentials" id="customCheckLogin" type="checkbox" class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150" style="margin-right: .5em;" @click="updatePrivacyCheck2" />
                                    <span class="ml-2 text-sm font-semibold text-blueGray-600">
                                    Capturer les données soumises
                                    </span>
                                </label>
                            </div>
                        </div>
                        <div class="row w-100">
                            <div class="form-group py-2 pe-0 pe-md-1 ">
                                <label class="d-flex align-items-center cursor-pointer">
                                    <input v-model="landingNew.capturePassword" id="customCheckLogin" type="checkbox" class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150" style="margin-right: .5em;" @click="updatePassCheck2" />
                                    <span class="ml-2 text-sm font-semibold text-blueGray-600" :style="{ color: 'black', border: border }">
                                    Capturer les mots de passe
                                    </span>
                                </label>
                                <p class="mb-2 case"><strong><i class="fa fa-question"></i> Warning: </strong>Credentials re currently <strong>not encrypted.</strong> This means that captured passwords ar stored in the database as cleartext. Be careful with this. </p>
                            </div>
                        </div>
                    </template>
                </scalable-modal>

                <scalable-modal @callback="confirmCreateSmtp" :break="exitOrgUpdateModal3" label="CRÉER UN PLAN" uid="cfa-modal-phish-smtp-create" @close="newSmtpForm=false" :open="newSmtpForm"  :advancedControl="true" validationButtonText="Nouveau Smtp" exitButtonText="Annuler" displayMode="flex" :style="'width: 60em; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
                    <template v-slot:content>
                        <!-- blo1 -->
                        <div class="row w-100">
                            <div class="form-group py-2 pe-0 pe-md-2 ">
                                <p class="mb-2"><strong>Name</strong></p>
                                <div class="d-flex flex-row">
                                    <input class="form-control" type="text" v-model="newSmtpName">
                                </div>
                            </div>
                        </div>
                        <div class="row w-100">
                            <div class="form-group py-2 pe-0 pe-md-2 ">
                                <p class="mb-2"><strong>Host</strong></p>
                                <div class="d-flex flex-row">
                                    <input class="form-control" type="text" v-model="newSmtpHost">
                                </div>
                            </div>
                        </div>
                        <div class="row w-100">
                            <div class="form-group py-2 pe-0 pe-md-2 ">
                                <p class="mb-2"><strong>Username (Email address)</strong></p>
                                <div class="d-flex flex-row">
                                    <input class="form-control" type="text" v-model="newSmtpUsername">
                                </div>
                            </div>
                        </div>
                        <div class="row w-100">
                            <div class="form-group py-2 pe-0 pe-md-2 ">
                                <p class="mb-2"><strong>Password</strong></p>
                                <div class="d-flex flex-row">
                                    <input class="form-control" type="text" v-model="newSmtpPassword">
                                </div>
                            </div>
                        </div>
                        <div class="row w-100">
                            <div class="form-group py-2 pe-0 pe-md-2 ">
                                <p class="mb-2"><strong>From Address</strong></p>
                                <div class="d-flex flex-row">
                                    <input class="form-control" type="text" v-model="newSmtpFromAddress">
                                </div>
                            </div>
                        </div>
                    </template>
                </scalable-modal>

                <scalable-modal @callback="confirmUpdateSmtp" :break="exitOrgUpdateModal3" label="CRÉER UN PLAN" uid="cfa-modal-phish-smtp-update" @close="newSmtpForm2=false" :open="newSmtpForm2"  :advancedControl="true" validationButtonText="Mettre à jour" exitButtonText="Annuler" displayMode="flex" :style="'width: 60em; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
                    <template v-slot:content>
                        <!-- blo1 -->
                        <div class="row w-100">
                            <div class="form-group py-2 pe-0 pe-md-2 ">
                                <p class="mb-2"><strong>Name</strong></p>
                                <div class="d-flex flex-row">
                                    <input class="form-control" type="text" v-model="smtpData2.name">
                                </div>
                            </div>
                        </div>
                        <div class="row w-100">
                            <div class="form-group py-2 pe-0 pe-md-2 ">
                                <p class="mb-2"><strong>Interface type</strong></p>
                                <div class="d-flex flex-row">
                                    <input class="form-control" disabled type="text" v-model="smtpData2.interface_type">
                                </div>
                            </div>
                        </div>
                        <div class="row w-100">
                            <div class="form-group py-2 pe-0 pe-md-2 ">
                                <p class="mb-2"><strong>SMTP From</strong></p>
                                <div class="d-flex flex-row">
                                    <input class="form-control" type="text" v-model="smtpData2.from_address">
                                </div>
                            </div>
                        </div>
                        <div class="row w-100">
                            <div class="form-group py-2 pe-0 pe-md-2 ">
                                <p class="mb-2"><strong>Host</strong></p>
                                <div class="d-flex flex-row">
                                    <input class="form-control" type="text" v-model="smtpData2.host">
                                </div>
                            </div>
                        </div>
                        <div class="row w-100">
                            <div class="form-group py-2 pe-0 pe-md-2 ">
                                <p class="mb-2"><strong>Username (Email address)</strong></p>
                                <div class="d-flex flex-row">
                                    <input class="form-control" type="text" v-model="smtpData2.username">
                                </div>
                            </div>
                        </div>
                        <div class="row w-100">
                            <div class="form-group py-2 pe-0 pe-md-2 ">
                                <p class="mb-2"><strong>Password</strong></p>
                                <div class="d-flex flex-row">
                                    <input class="form-control" type="text" v-model="smtpData2.password">
                                </div>
                            </div>
                        </div>
                        <div class="row w-100">
                            <TableMin :i="2" min :columns="headerColumns" :data="headers"   :hide-control="true"></TableMin>
                        
                        </div>
                    </template>
                </scalable-modal>
                <scalable-modal :disable-control="true" @callback="confirmUpdate" :break="exitOrgUpdateModal" label="CRÉER UN PLAN" uid="cfa-modal-phish-campaign-update" @close="newcampaignUpdate=false" :open="newcampaignUpdate"  :advancedControl="true" validationButtonText="Nouvelle sms campagne" exitButtonText="Annuler" displayMode="flex" :style="'width: 60em; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
            <template v-slot:content>
              <!--navigable-tabs | removed from the wizard-->
                <Wizard
                    squared-tabs
                    card-background
                    
                    scrollable-tabs
                    :nextButton="nextButtonOptionss"
                    :backButton="prevButtonOptionss"
                    :doneButton="doneButtonOptions"
                    :custom-tabs="[
                        {
                          title: 'Initialisation',
                        },
                        {
                          title: 'Choix de la cible',
                        },
                        {
                          title: 'Groupe',
                        },
                        {
                          title: 'Plannification',
                        },
                        
                    ]"
                    :beforeChange="onTabBeforeChange"
                    @change="onChangeCurrentTab"
                    @complete:wizard="wizardUpdated"
                >
                    <transition-group name="slide-fade--">
                      <div v-if="currentTabIndex === 0" class="stepper-body">
                        
                          <div class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Nom</strong></p>
                                  <div class="d-flex flex-row">
                                    <input class="form-control" type="text" placeholder="Choisissez un nom unique pour votre campagne" v-model="campaignByid.name">
                                     
                                  </div>
                              </div>
                          </div>
                         
                          <div class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Titre</strong></p>
                                  <div class="d-flex flex-row">
                                      <select class="form-control" v-model="micro_learning_id">
                                          <!-- <option :selected="!campaignByid?.micro_learning?.id"  disabled >Selectionner un microlearning cible pour la campagne</option> 
                                          :selected="campaignByid?.micro_learning?.id == group.id" 
                                          -->
                                          <option :selected="!micro_learning_id"  disabled >Selectionner un microlearning cible pour la campagne</option>
                                          <option v-for="group in microlearning" :value="group.id" v-bind:key="group" >{{ group.title }}</option>
                                      </select>
                                  </div>
                                  
                              </div>
                          </div>
                          <div class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Campaign Type</strong></p>
                                  <div class="d-flex flex-row">
                                       <select class="form-control" v-model="campaignByid.campaign_type" id="selectType">
                                          <option value="default" selected disabled>Selectionner le type de campagne</option>
                                          <option value="sms">SMS</option>
                                          <option value="email">EMAIL</option>
                                      </select>
                                  </div>
                              </div>
                          </div>
                          
                      </div>
                      <div v-else-if="currentTabIndex === 1" class="stepper-body">
                        <div v-if="campaignByid.campaign_type == 'sms'" class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Nom de l'expéditeur du SMS</strong></p>
                                  <div class="d-flex flex-row">
                                    <input class="form-control" type="text" placeholder="Entrez le nom de l'expéditeur du SMS" v-model="campaignByid.sms_send_name">
                                  </div>
                              </div>
                          </div>
                          <div v-if="campaignByid.campaign_type == 'email'" class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Nom de l'expéditeur de l'e-mail</strong></p>
                                  <div class="d-flex flex-row">
                                    <input class="form-control" type="text" placeholder="Entrez le nom de l'expéditeur de l'e-mail" v-model="campaignByid.email_send_name">
                                  </div>
                              </div>
                          </div>
                          <div v-if="campaignByid.campaign_type == 'email'" class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Signature de l'expéditeur de l'e-mail</strong></p>
                                  <div class="d-flex flex-row">
                                    <input class="form-control" type="text" placeholder="Saisissez la signature de l'expéditeur de l'e-mail" v-model="campaignByid.email_send_signature">
                                  </div>
                              </div>
                          </div>
                          <div v-if="campaignByid.campaign_type == 'sms'" class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Signature de l'expéditeur du SMS</strong></p>
                                  <div class="d-flex flex-row">
                                    <input class="form-control" type="text" placeholder="Saisir la signature de l'expéditeur du SMS" v-model="campaignByid.sms_send_signature">
                                  </div>
                              </div>
                          </div>
                          <div class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Campaign Target</strong></p>
                                  <div class="d-flex flex-row">
                                       <select class="form-control" v-model="newCampaignTarget" id="selectType">
                                          <option  selected disabled>Selectionner le target de campagne</option>
                                          <option value="GROUP">Group</option>
                                          <option value="SERVICE">Service</option>
                                      </select>
                                  </div>
                              </div>
                          </div>
                      </div>
                      
                      <div v-else-if="currentTabIndex === 2" class="stepper-body">
                          <div v-if="newCampaignTarget ==  'GROUP'" class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Groupes cibles</strong></p>
                                  <div class="d-flex flex-row">
                                      <select class="form-control" v-model="campaignByid.group">
                                          <option :value="null" selected disabled>Selectionner un groupe cible pour la campagne</option>
                                          <option v-for="group in groupOrgData" :value="group.id" v-bind:key="group">{{ group.name }}</option>
                                      </select>
                                  </div>
                              </div>
                          </div>
                          <div v-if="newCampaignTarget ==  'SERVICE'" class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Service</strong></p>
                                  <div class="d-flex flex-row">
                                      <select class="form-control" v-model="campaignByid.service">
                                          <option :value="null" selected disabled>Selectionner un service cible pour la campagne</option>
                                          <option v-for="service in serviceOrgData" :value="service.id" v-bind:key="service">{{ service.name }}</option>
                                      </select>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div v-else-if="currentTabIndex === 3" class="stepper-body">
                          <div class="row w-100 m-inherit mt-2">
                              <div class="form-group py-2 px-0">
                                  <p class="mb-2"><strong>Date de planification <span style="color: red; opacity: 0.5; font-size: 0.9em;">(un délai d'attente de 03 heures sera ajouté à l'heure de lancement choisie)</span></strong></p>
                                  <div class="d-flex flex-row">
                                      <VueDatePicker placeholder="Choisir la date de planification" v-model="planification_date" locale="fr" cancelText="Annuler" selectText="Choisir" :title="'DEFINIR UNE DATE'" :minDate="minDate"></VueDatePicker>
                                  </div>
                              </div>
                          </div>
                           <div class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Message par défaut</strong></p>
                                  <div class="d-flex flex-row">
                                    <input class="form-control" type="text" placeholder="Entrez le message par défaut" v-model="campaignByid.default_message">
                                     
                                  </div>
                              </div>
                           </div>
  
                      </div>
                      
                    </transition-group>
                </Wizard>
            </template>
          </scalable-modal>
          <scalable-modal  @callback="wizardUpdatedss" :break="exitOrgUpdateModal3" label="CRÉER UN PLAN" uid="cfa-modal-phish-template-creates" @close="newDomainForm3=false" :open="newDomainForm3"  :advancedControl="true" validationButtonText="Nouveau email campaign" exitButtonText="Annuler" displayMode="flex" :style="'width: 60em; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
                    <template v-slot:content>
                       
                        
                        <div class="row w-100">
                            <div class="form-group py-2 pe-0 pe-md-2 ">
                                <p class="mb-2"><strong>url shortener</strong></p>
                                <div class="d-flex flex-row">
                                    <select class="form-control" v-model="selectedurlshortener" >
  <option selected disabled value="">Sélectionnez url shortener</option>
  <option v-for="declare in declarationData" :value="declare.id" v-bind:key="declare.name">{{declare.name}}</option>
</select>
                                </div>
                            </div>
                        </div>
                        
                        
                    </template>
                </scalable-modal>
                <scalable-modal @callback="wizardUpdateds" :break="exitOrgUpdateModal2" label="CRÉER UN PLAN" uid="cfa-modal-phish-template-created" @close="newDomainForm2=false" :open="newDomainForm2"  :advancedControl="true" validationButtonText="Nouveau campaign" exitButtonText="Annuler" displayMode="flex" :style="'width: 60em; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
                    <template v-slot:content>
                        <!-- blo1 -->
                        
                        <div class="row w-100">
                            <div class="form-group py-2 pe-0 pe-md-2 ">
                                <p class="mb-2"><strong>url shortener</strong></p>
                                <div class="d-flex flex-row">
                                    <select class="form-control" v-model="selectedurlshortener" >
  <option selected disabled value="">Sélectionnez url shortener</option>
  <option v-for="declare in declarationData" :value="declare.id" v-bind:key="declare.name">{{declare.name}}</option>
</select>
                                </div>
                            </div>
                        </div>
                        <div class="row w-100">
                            <div class="form-group py-2 pe-0 pe-md-2 ">
                                <p class="mb-2"><strong>sms provider</strong></p>
                                <div class="d-flex flex-row">
                                    <select class="form-control" v-model="selectedsmsprovider" >
  <option selected disabled value="">Sélectionnez sms provider</option>
  <option v-for="declare in sms_provider" :value="declare.id" v-bind:key="declare.name">{{declare.name}}</option>
</select>
                                </div>
                            </div>
                        </div>
                        
                    </template>
                </scalable-modal>
            </template>
        </c-f-a-nav>
        <c-f-a-footer></c-f-a-footer>
    </div>
</template>

<script>
import CFAFooter from '@/components/nav/CFAFooter.vue'
import CFANav from '@/components/nav/CFANav.vue'
import Table from "@/components/shared/table/Table.vue";

import Wizard from 'form-wizard-vue3'
  import VueDatePicker from '@vuepic/vue-datepicker';
import TableMin from "@/components/shared/table/TableMin.vue";
import ScalableModal from "@/components/shared/modal/ScalableModal.vue";
import {getAPI} from "@/axios-api";
import { VueEditor } from "vue3-editor";
import axios from 'axios';
import { getUpdatedKeys } from '@/utils/getUpdatedObject';

export default {
    name: 'PhishingView',
    components: {
        ScalableModal,
        Wizard,
    VueDatePicker,
       
        Table,
        TableMin,
        CFAFooter,
        CFANav,
        VueEditor
    },
    data () {
        return {
            headers:[],
            htmlForEditor: "",
            waitingAPIResponse: false,
            campagns:[],
            
            isConnected: true,
            alertString: null,
            currentTab: 'campaign_activate',
            declarationDatas:[],
            campaignsAll: [],
            campaignsAll2: [],
            campaignsAll3: [],
            campaignsAllColumns: ['Nom de la campaigne','Statut', 'Type','Cible', 'Date création','Date validation', 'Actions'],
            campaignsAllToListen: ['Actions'],
            headerColumns:['Header','Value'],
            groupAllColumns: ['n°', 'Name', 'Users', 'Last updated', 'Actions'],
            groupAllToListen: ['Actions'],
            groupAll: [],
            newcampaignUpdate:false,

            userGroupAll: [],
            userGroupAllColumns: ['n°', 'Nom', 'Prénoms', 'Email', 'Position', 'Actions'],
            userGroupAllToListen: ['Actions'],
            planification_date:'',
            templateAll: [],
            declarationAll:[],
            templateAllColumns: ['n°', 'Name', 'Subject', 'Preview', 'Actions'],
            templateAllToListen: ['Actions'],
            declarationAllColumns:['n°','Template','Landing','Actions'],
            declarationAllToListen:['Actions'],
            landingAll: [],
            declarationData:[],
            landingAllColumns: ['n°', 'Name', 'Url', 'Preview', 'Actions'],
            landingAllToListen: ['Actions'],
            organisation:null,
            openDeletionBox: false,
            tempDeleteID: null,
            newCampaignTarget:'',
            tempDeleteName: null,
            updateGroupPayload: null,
            openUpdateGroup: false,
            openCreateGroup:false,
            name_check: null,
            callbackPayload: null,
            exitOrgModalDel: null,
            exitOrgUpdateModal: null,
            exitOrgUpdateModal2: null,
            newFirstname: null,
            newLastname: null,
            newEmail: null,
            newPosition: null,
            
            newDomainForm3:false,
            newDomainForm2:false,
            openDeletionCampaignBox: false,
            openDeletionCampaignBox2: false,
            openDeletionCampaignBox3: false,
            newCampaignForm: false,

            newCampaignName: null,
            newCampaignType: null,
            newCampaignDateStart: null,
            newCampaignDateEnd: null,
            newCampaignGroupe: null,
            newCampaignEmailTemplate: null,
            newCampaignLandingTemplate: null,
            selectedurlshortener:'9462e3bd-3c5f-4803-8614-3fa7a50de04d',
       selectedsmsprovider:'a83fd831-f368-4484-b20c-f0a949206b6a',
            newTemplateForm: false,
            newTemplateName: null,
            newTemplateSubject: null,
            newTemplateEnvelopeSender: null,
            newTemplateHtmlCode: null,
            newTemplateOrganisation: null,
            allOrganisation: [],
            waitingAPITemplate: false,
            templatesData: [],
            microlearning:[],
            newLandingForm: false,
            newLandingName: null,
            newLandingHtmlCode: null,
            newLandingUrl: null,
            newLandingOrganisation: null,
            LandingsData: [],
            serviceAllData:[],
            urlWeb: "",
            validated: false,
            inProgress: 0,
            validationWaiting: 0,
            archived: 0,
            groupLength: 0,

            smtpColumns: ['n°', 'Name', 'Host', 'Username', 'Password', 'From address', 'Gophish sending profile id', 'Actions'],
            smtpAllToListen: ['Actions'],
            smtpLength: 0,
            smtpData: [],
            smtpAll: [],
            selectedLanding:"",
            selectedTemplate:"",
            newTemplateForm2: false,
            newTemplateName2: null,
            newTemplateSubject2: null,
            newTemplateEnvelopeSender2: null,
            newTemplateHtmlCode2: null,
            exitOrgUpdateModal3: null,
            sms_provider:[],
            newTemplateattachments: [],
            newTemplateattachments2: [],

            newTemplateCapturePrivacy: false,
            newTemplateCapturePrivacy2: false,
            newTemplateCapturePass: false,
            newTemplateCapturePass2: false,

            templateData: [],
            openDeletionBox2: false,
            templateId: "",
            currentTabIndex: 0,
            newLandingCapturePrivacy: null,
            newLandingCapturePass: null,

            newSmtpForm: false,
            newSmtpName: null,
            newSmtpHost: null,
            newSmtpUsername: null,
            newSmtpPassword: null,
            newSmtpFromAddress: null,
            campaignByid:[],
            templateToDelete: "",
            smtpData2: [],
            smtpToUpdate: "",
            newSmtpForm2: false,
            name:'',
            landingToDelete: "",
            newCampaignService:'',
            
            landingNew: [],
            landingToUpdate: "",
            newLandingForm2: false,
            groupUsers:[],
            selectedGroup:null,
            groupOrgData: [],
            serviceOrgData: [],
            campaignToUpdate: null,
        }
    },


    // groupAll: [{'n°':'1', 'Nom':'Groupe 1', 'Utilisateurs':'03', 'Denière modification':'04/04/2023 à 09:46:17',
    //             'Actions':'<div class="d-flex align-items-center">' +
    //                 '<button btn-id="12E3" btn-action="edit" class="ms-2 btn cfa-btn-light btn-action"><i class="fa fa-eye"> ÉDITER / VALIDER &nbsp;</i></button>' +
    //                 '<button btn-id="12E3" btn-action="delete" class="ms-2 btn btn-danger btn-action"><i class="fa fa-trash"></i> SUPPRIMER</button>' +
    //                 '</div>'
    //         }],



    methods: {
        addUsers(){
        this.groupUsers.push({firstname:this.newFirstname,lastname:this.newLastname, email:this.newEmail,position:this.newPosition});
        this.newFirstname='',
        this.newLastname='',
        this.newEmail='',
        this.newPosition='',
        console.log(this.groupUsers.length);
        for(var i=0; i<= this.groupUsers.length;i++){
            let row = this.groupUsers[i];
            this.userGroupAll.push({'n°': (i+1),'Nom':row.firstname,'Prénoms':row.lastname,'Email':row.email,'Position':row.position});
        }
        },
        updatePrivacyCheck() {
            this.newTemplateCapturePrivacy = !this.newTemplateCapturePrivacy
        },
        updatePassCheck() {
            this.newTemplateCapturePass = !this.newTemplateCapturePass
        },
        updatePrivacyCheck2() {
            this.captureCredentials = !this.captureCredentials
        },
        updatePassCheck2() {
            this.capturePassword = !this.capturePassword
        },
        onChangeCurrentTab(index, oldIndex) {
            console.log(index, oldIndex);
            this.currentTabIndex = index;
          },
          onTabBeforeChange() {
            if (this.currentTabIndex === 0) {
                console.log('First Tab');
            }
            console.log('All Tabs');
          },
          checkCampaigntarget(){
            if(this.campaignByid.service == null){
                this.newCampaignTarget= 'GROUP';
            }if(this.campaignByid.group == null){
                this.newCampaignTarget='SERVICE';
            }
          },
        confirmCreates(){
            this.waitingAPITemplate = true
            getAPI.post(`groupes/`,{
                name:this.name,
                organisation:this.organisation,
                users:this.groupUsers

            }).then(response => {
                    if(response.status === 201) {
                        this.getAllGroup()
                            let msg = 'Groupe MAJ avec succès!'
                            this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                            this.exitOrgUpdateModal = Date.now()
                    }
                    else {
                        let msg = 'Oups ! something went wrong.'
                        this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    setTimeout(() => {
                        this.waitingAPITemplate = false
                    }, 2000);
                })
                .catch(error => {
                    if (error.response) {
                        this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                    }
                    else if (error.request) {
                        let msg = 'The request was made but no response was received. Please check your network.'
                        this.alertString = `${msg}|alert-no|8000::`+Date.now()
                    }
                    else {
                        let msg = 'Oups ! something went wrong.'
                        this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.waitingAPITemplate = false
                })
        },
        async wizardUpdated() {
              
              const groups = [];
              const service = [];
            //   const serve=["93c1afcb-5838-4ae3-905c-80e6196f4204"];
            //   const group=["3421b42e-55f8-46fb-ae95-32c3a48bc19e"];
              service.push(this.newCampaignService)
              groups.push(this.newCampaignGroupe);
              console.log(this.campaignByid.planification_date);
              // let newCampaignDateStartF = new Date(this.planification_date);
              // newCampaignDateStartF = addHours(this.planification_date, 3);
              let micro_learning={};
               micro_learning={id:this.micro_learning_id,
                bottom_email_message:this.campaignByid.micro_learning.bottom_email_message,
                title:this.campaignByid.micro_learning.title,
                micro_learning_url:this.campaignByid.micro_learning.micro_learning_url,
                sms_message:this.campaignByid.micro_learning.sms_message,
                top_email_message:this.campaignByid.micro_learning.top_email_message,
                im_message:this.campaignByid.micro_learning.im_message,
                duration:this.campaignByid.micro_learning.duration,
                description:this.campaignByid.micro_learning.description,

              };
              console.log(micro_learning);
              /*
                    {
                    //name: this.campaignByid.name,
                    micro_learning: micro_learning.id,
                    campaign_type: this.campaignByid.campaign_type,
                    sms_send_name: this.campaignByid.sms_send_name || null,
                    group: this.campaignByid.group || null,
                    email_send_name: this.campaignByid.email_send_name || null,
                    sms_send_signature: this.campaignByid.sms_send_signature || null,
                    email_send_signature: this.campaignByid.email_send_signature || null,
                    planification_date: this.planification_date,
                    default_message:this.campaignByid.default_message,
                    is_validated_by_admin: false,
                    service:this.campaignByid.service || null,
                    status:"available"
                    
                }
              */
            const payload = getUpdatedKeys(this.campaignToUpdate, this.campaignByid)
            payload.micro_learning = payload.micro_learning.id
            if (this.campaignByid.group) {
                payload.group = this.campaignByid.group
            } else if (this.campaignByid.service) {
                payload.service = this.campaignByid.service
            } 
            console.log(payload)
              await getAPI.patch(`micro_learning/campaigns/${this.campaignByid.id}/`, payload)
                  .then(async response => {
                    console.log(response)
                      if(response.status == 200) {
                          //this.newcampaignUpdate = false
                          //this.exitOrgUpdateModal = Date.now();
                          //await this.getAllGroup()
                          let msg = 'Campagne mise à jour avec succès!'
                          this.campaignWaiting = response.data;
                          console.log("Camp: ", this.campaignWaiting)
                          this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                          await this.getAllCampaign()
                         
                      }
                      else {
                          
                          let msg = 'Oups ! something went wrong.'
                          this.alertString = `${msg}|alert-no|5000::`+Date.now()
                        }
                    //   this.exitOrgUpdateModal = Date.now();
                    //   this.newCampaignUpdate = false;
                    //   this.$swal({
                    //       title: 'Campagne créée avec succès',
                    //       text: 'Campagne créée avec succès. En attente de validation.',
                    //       type: 'warning',
                    //       showCancelButton: false,
                    //       confirmButtonText: "D'accord !"
                    //   });
                  })
                  .catch(error => {
                      if (error.response) {
                          this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          let msg = 'The request was made but no response was received. Please check your network.'
                          this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          let msg = 'Oups ! something went wrong.'
                          this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      //this.exitOrgUpdateModal = Date.now();
                      //this.newCampaignUpdate = false;
                  });
          
          
      },
      getAllDeclaration() {
            this.waitingAPITemplate = true
            getAPI.get(`smshing/url_shortener/`)
                .then(response => {
                    if(response.status === 200) {
                        this.declarationData = response.data.data;
                        
                    }
                    else {
                        let msg = 'Oups ! something went wrong.'
                        this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    setTimeout(() => {
                        this.waitingAPITemplate = false
                    }, 2000);
                })
                .catch(error => {
                    if (error.response) {
                        this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                    }
                    else if (error.request) {
                        let msg = 'The request was made but no response was received. Please check your network.'
                        this.alertString = `${msg}|alert-no|8000::`+Date.now()
                    }
                    else {
                        let msg = 'Oups ! something went wrong.'
                        this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.waitingAPITemplate = false
                })
        },
        getAllService () {
              this.waitingAPIResponse = true
              getAPI.get(`services`)
                  .then(response => {
                      if(response.status == 200) {
                          
                          this.serviceAllData = response.data.data;
                          
                          
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      
                  })
                  .catch(error => {
                      if (error.response) {
                          //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          //let msg = 'The request was made but no response was received. Please check your network.'
                          //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      
                  })
          },
         getAllSmsProvider() {
            this.waitingAPITemplate = true
            getAPI.get(`smshing/sms_provider/`)
                .then(response => {
                    if(response.status === 200) {
                        this.sms_provider = response.data.data;
                        
                    }
                    else {
                        let msg = 'Oups ! something went wrong.'
                        this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    setTimeout(() => {
                        this.waitingAPITemplate = false
                    }, 2000);
                })
                .catch(error => {
                    if (error.response) {
                        this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                    }
                    else if (error.request) {
                        let msg = 'The request was made but no response was received. Please check your network.'
                        this.alertString = `${msg}|alert-no|8000::`+Date.now()
                    }
                    else {
                        let msg = 'Oups ! something went wrong.'
                        this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.waitingAPITemplate = false
                })
        },
      wizardUpdatedss() {
              console.log(this.campaignByid.planification_date);
              // let newCampaignDateStartF = new Date(this.planification_date);
              // newCampaignDateStartF = addHours(this.planification_date, 3);
              let micro_learning={};
               micro_learning={id:this.micro_learning_id,
                

              };
              let micro=this.campaignByid.micro_learning
              console.log(micro_learning);
              getAPI.post(`micro_learning/validate_campaign`, {
                  //name: this.campaignByid.name,
                  campaign_id: this.campaignByid.id,
                  url_shortener_id: this.selectedurlshortener,
                  microlearning_id: micro.id,
                  test_email: "onesime.adefoulou@gmail.com",
                  is_validated_by_admin: true,
                  
                  
              })
                  .then(response => {
                      if(response.status == 201) {
                           
                          let msg = 'Campagne validation avec succès.'
                          this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                        //    this.exitOrgUpdateModal3 = Date.now();
                           this.newDomainForm3 = false;
                           
                        //     window.location.reload();
                        //   this.getAllGroup()
                          this.getAllCampaign()
                          
                      }
                      else {
                          
                          let msg = 'Oups ! something went wrong.'
                          this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                    //   this.exitOrgUpdateModal3 = Date.now();
                    
                      
                  })
                  .catch(error => {
                      if (error.response) {
                         let msg = 'Oups ! something went wrong.'
                          this.alertString = `${msg}|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          let msg = 'The request was made but no response was received. Please check your network.'
                          this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          let msg = 'Oups ! something went wrong.'
                          this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                    //   this.exitOrgUpdateModal2 = Date.now();
                      this.newCampaignUpdate = false;
                  });
          
          
      },
          wizardUpdateds() {
              
             
              console.log(this.campaignByid.planification_date);
              // let newCampaignDateStartF = new Date(this.planification_date);
              // newCampaignDateStartF = addHours(this.planification_date, 3);
              let micro_learning={};
               micro_learning={id:this.micro_learning_id,
                

              };
              let micro=this.campaignByid.micro_learning
              console.log(micro_learning);
              getAPI.post(`micro_learning/validate_campaign`, {
                  //name: this.campaignByid.name,
                  campaign_id: this.campaignByid.id,
                  microlearning_id: micro.id,
                  sms_provider_id: this.selectedsmsprovider,
                  url_shortener_id: this.selectedurlshortener, 
                  test_phone_number: '+250780958495',
                  is_validated_by_admin: true,
                  
                  
              })
                  .then(response => {
                      if(response.status == 201) {
                           
                            
                            let msg = 'Campagne validation avec succès.'
                            this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                            this.newDomainForm2 = false;
                            // this.exitOrgUpdateModal2 = Date.now();
                            //   window.location.reload();
                        //   this.getAllGroup()
                          this.getAllCampaign()
                          
                      }
                      else {
                          
                          let msg = 'Oups ! something went wrong.'
                          this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                     
                      
                  })
                  .catch(error => {
                      if (error.response) {
                          this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          let msg = 'The request was made but no response was received. Please check your network.'
                          this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          let msg = 'Oups ! something went wrong.'
                          this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                    //   this.exitOrgUpdateModal = Date.now();
                      this.newCampaignUpdate = false;
                  });
          
          
      },
        getAllGroups() {
            this.waitingAPITemplate = true
            getAPI.get(`groupes/`)
                .then(response => {
                    console.log(response)
                    if(response.status === 200) {
                        this.groupData = response.data.data;
                        this.groupAll = []
                        this.groupLength = this.groupData.length;
                        for(let i=0; i<this.groupData.length; i++) {
                            let row = this.groupData[i];

                            // let btn = `<div class="d-flex align-items-center">`;
                            // //btn += `<button btn-id="${row.id}" btn-action="edit" class="ms-2 btn cfa-btn-light btn-action"><i class="fa fa-eye"> ÉDITER&nbsp;</i></button>`;
                            // btn += `<button btn-id="${row.id}" btn-action="delete" class="ms-2 btn btn-danger btn-action"><i class="fa fa-trash"></i></button>`;
                            // btn += `</div>`;

                            this.groupAll.push({
                                'n°': (i+1),
                                'Name': `<span class="selectable">${row.name}</span>`,
                                'Users': `${row.users.length}`,
                                'Last updated':`<span>${this.convertDate(row.updated_at, true)}</span>`,
                                // 'Actions':'<div class="d-flex align-items-center">' +
                                // `<button btn-id="${row.id}" btn-action="edit" class="ms-2 btn cfa-btn-light btn-action"><i class="fa fa-eye"> &nbsp;</i>edit</button>` +
                                // `<button btn-id="${row.id}" btn-action="delete" class="ms-2 btn btn-danger btn-action"><i class="fa fa-trash"></i> SUPPRIMER</button>` +
                                // '</div>'
                                // 'Actions': btn, name:
                                // row.name, id: row.id
                            });
                        }
                        console.log("VOici groupAll: ", this.groupAll);
                    }
                    else {
                        let msg = 'Oups ! something went wrong.'
                        this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    setTimeout(() => {
                        this.waitingAPITemplate = false
                    }, 2000);
                })
                .catch(error => {
                    if (error.response) {
                        this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                    }
                    else if (error.request) {
                        let msg = 'The request was made but no response was received. Please check your network.'
                        this.alertString = `${msg}|alert-no|8000::`+Date.now()
                    }
                    else {
                        let msg = 'Oups ! something went wrong.'
                        this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.waitingAPITemplate = false
                })
        },
        getAllSmtps() {
            getAPI.get(`phishing/sending-profiles/`)
                .then(response => {
                    if(response.status === 200) {
                        this.smtpData = response.data.data;
                        this.smtpAll = []
                        this.headers = this.smtpData.headers;
                        this.smtpLength = this.smtpData.length;
                        for(let i=0; i<this.smtpLength; i++) {
                            let row = this.smtpData[i];
                            this.smtpAll.push({
                                'n°': (i+1),
                                'Name': `<span class="selectable">${row.name}</span>`,
                                'Host': `${row.host}`,
                                'Username': `<span class="selectable">${row.username}</span>`,
                                'Password': `${row.password}`,
                                'From address': `<span class="selectable">${row.from_address}</span>`,
                                'Gophish sending profile id': `${row.gophish_sending_profile_id}`,
                                // 'Actions':'<div class="d-flex align-items-center">' +
                                //     `<button btn-id="${row.id}" btn-action="editSmtp" class="ms-2 btn btn-primary btn-action"><i class="fa fa-edit"></i>edit</button>` +
                                //     `<button btn-id="${row.id}" btn-action="deleteSmtp" class="ms-2 btn btn-danger btn-action"><i class="fa fa-trash"></i>delete</button>` +
                                //     '</div>'
                            });
                            // this.headers.push({'Headers':`${row.headers.key}`,'Value':`${row.headers.value}`});
                        }
                        console.log("VOici groupAll: ", this.groupAll);
                    }
                    else {
                        let msg = 'Oups ! something went wrong.'
                        this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    setTimeout(() => {
                        this.waitingAPITemplate = false
                    }, 2000);
                })
                .catch(error => {
                    if (error.response) {
                        this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                    }
                    else if (error.request) {
                        let msg = 'The request was made but no response was received. Please check your network.'
                        this.alertString = `${msg}|alert-no|8000::`+Date.now()
                    }
                    else {
                        let msg = 'Oups ! something went wrong.'
                        this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.waitingAPITemplate = false
                })
        },
        getAllMicroLearning () {
                         this.waitingAPIResponse = true
              getAPI.get(`micro_learning/courses/`)
                  .then(response => {
                    console.log(response)
                      if(response.status == 200) {
                          
                          this.microlearning = response.data.results;
                          
                          
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      
                  })
                  .catch(error => {
                      if (error.response) {
                          //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          //let msg = 'The request was made but no response was received. Please check your network.'
                          //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      
                  })
          },
        getAllOrgs () {
            this.waitingAPIResponse = true
            getAPI.get('organisations')
                .then(response => {
                    if(response.status === 200) {
                        // this.allOrganisation = response.data.results
                        this.allOrganisation = response.data.results
                    }
                    else {
                        let msg = 'Oups ! something went wrong.'
                        this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.waitingAPIResponse = false
                })
                .catch(error => {
                    if (error.response) {
                        this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
                    }
                    else if (error.request) {
                        let msg = 'The request was made but no response was received. Please check your network.'
                        this.alertString = `${msg}|alert-no|8000::`+Date.now()
                    }
                    else {
                        let msg = 'Oups ! something went wrong.'
                        this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.waitingAPIResponse = false
                })
        },
        getAllTemplate() {
            this.waitingAPITemplate = true
            getAPI.get(`phishing/templates/`)
                .then(response => {
                    if(response.status === 200) {
                        this.templatesData = response.data.data;
                        this.templateAll = []
                        for(let i=0; i<this.templatesData.length; i++) {
                            let row = this.templatesData[i];

                            // let btn = `<div class="d-flex align-items-center">`;
                            // //btn += `<button btn-id="${row.id}" btn-action="edit" class="ms-2 btn cfa-btn-light btn-action"><i class="fa fa-eye"> ÉDITER&nbsp;</i></button>`;
                            // btn += `<button btn-id="${row.id}" btn-action="delete" class="ms-2 btn btn-danger btn-action"><i class="fa fa-trash"></i></button>`;
                            // btn += `</div>`;

                            this.templateAll.push({
                                'n°': (i+1),
                                'Name': `<span class="selectable">${row.name}</span>`,
                                'Subject': `${row.subject}`,
                                'Preview':`<iframe style="min-width: 623px !important; width: 100%; height: 100px;" srcdoc="${row.html_code}"></iframe>`,
                                // 'Actions':'<div class="d-flex align-items-center">' +
                                //     `<button btn-id="${row.id}" btn-action="edit" class="ms-2 btn btn-primary btn-action"><i class="fa fa-edit"></i>edit</button>` +
                                //     `<button btn-id="${row.id}" btn-action="delete" class="ms-2 btn btn-danger btn-action"><i class="fa fa-trash"></i>delete</button>` +
                                //     '</div>'
                                // 'Actions': btn, name:
                                // row.name, id: row.id
                            });
                        }
                    }
                    else {
                        let msg = 'Oups ! something went wrong.'
                        this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    setTimeout(() => {
                        this.waitingAPITemplate = false
                    }, 2000);
                })
                .catch(error => {
                    if (error.response) {
                        this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                    }
                    else if (error.request) {
                        let msg = 'The request was made but no response was received. Please check your network.'
                        this.alertString = `${msg}|alert-no|8000::`+Date.now()
                    }
                    else {
                        let msg = 'Oups ! something went wrong.'
                        this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.waitingAPITemplate = false
                })
        },
        
        landingUpdate(pos) {
            this.newLandingForm2 = true;
            this.landingNew = pos
        },
        getCallToActionForLanding (payload) {
            if(payload.action_type == 'edit') {
                this.landingToDelete = payload.id;
                getAPI.get(`phishing/landing-page/${payload.id}`)
                .then(response => {
                    if(response.status === 200) {
                        this.landingUpdate(response.data);
                    }
                    else {
                        let msg = 'Oups ! something went wrong.'
                        this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    setTimeout(() => {
                        this.waitingAPITemplate = false
                    }, 2000);
                })
                .catch(error => {
                    if (error.response) {
                        this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                    }
                    else if (error.request) {
                        let msg = 'The request was made but no response was received. Please check your network.'
                        this.alertString = `${msg}|alert-no|8000::`+Date.now()
                    }
                    else {
                        let msg = 'Oups ! something went wrong.'
                        this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.waitingAPITemplate = false
                })
            }
            if(payload.action_type == 'delete') {
                this.openDeletionCampaignBox3 = true;
                this.landingToDelete = payload.id;
            }
        },
        confirmDeletionLanding() {
            getAPI.delete(`phishing/landing-page/${this.landingToDelete}`)
                .then(response => {
                    if(response.status == 204) {
                        this.getAllLanding()
                        let msg = 'Landing page suprimé avec succès!'
                        this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                        this.exitOrgModalDel = Date.now()
                    }
                    else {
                        let msg = 'Oups ! something went wrong.'
                        this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.exitOrgModalDel = Date.now()
                })
                .catch(error => {
                    if (error.response) {
                        this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
                    }
                    else if (error.request) {
                        let msg = 'The request was made but no response was received. Please check your network.'
                        this.alertString = `${msg}|alert-no|8000::`+Date.now()
                    }
                    else {
                        let msg = 'Oups ! something went wrong.'
                        this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.exitOrgModalDel = Date.now()
                })
        },
        confirmUpdateLanding() {
            if(this.landingNew.name != null && this.landingNew.url != null &&  this.landingNew.html_code != null) {
                getAPI.put(`phishing/sending-profile/${this.landingToDelete}/`, {
                    name: this.landingNew.name,
                    url: this.landingNew.url,
                    html_code: this.landingNew.html_code,
                    capture_credentials: this.landingNew.captureCredentials || false,
                    capture_passwords: this.landingNew.capturePassword || false,
                })
                    .then(response => {
                        if(response.status == 200 || response.status == 201) {
                            this.getAllSmtps()
                            let msg = 'Landing page mis à jour avec succès!'
                            this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                            this.exitOrgUpdateModal3 = Date.now()
                            console.log("Smtp créé !")
                        }
                        else {
                            let msg = 'Oups ! something went wrong.'
                            this.alertString = `${msg}|alert-no|5000::`+Date.now()
                        }
                        this.exitOrgUpdateModal3 = Date.now()
                    })
                    .catch(error => {
                        if (error.response) {
                            this.alertString = `${error.response.data.redirect_url[0]}|alert-no|10000::`+Date.now()
                        }
                        else if (error.request) {
                            let msg = 'The request was made but no response was received. Please check your network.'
                            this.alertString = `${msg}|alert-no|8000::`+Date.now()
                        }
                        else {
                            let msg = 'Oups ! something went wrong.'
                            this.alertString = `${msg}|alert-no|5000::`+Date.now()
                        }
                        this.exitOrgUpdateModal3 = Date.now()
                    })
            }
            else {
                let msg = 'Oups ! Veuillez remplir le champ convenablement.'
                this.alertString = `${msg}|alert-no|5000::`+Date.now()
                this.exitOrgUpdateModal3 = Date.now()
                console.log("Champ manquant !")
            }
        },
        getAllLanding() {
            this.waitingAPITemplate = true
            getAPI.get(`phishing/landing-pages/`)
                .then(response => {
                    if(response.status === 200) {
                        this.LandingsData = response.data.data;
                        this.landingAll = []
                        for(let i=0; i<this.LandingsData.length; i++) {
                            let row = this.LandingsData[i];

                            // let btn = `<div class="d-flex align-items-center">`;
                            // //btn += `<button btn-id="${row.id}" btn-action="edit" class="ms-2 btn cfa-btn-light btn-action"><i class="fa fa-eye"> ÉDITER&nbsp;</i></button>`;
                            // btn += `<button btn-id="${row.id}" btn-action="delete" class="ms-2 btn btn-danger btn-action"><i class="fa fa-trash"></i></button>`;
                            // btn += `</div>`;

                            this.landingAll.push({
                                'n°': (i+1),
                                'Name': `<span class="selectable">${row.name}</span>`,
                                'Url': `${row.redirect_url}`,
                                'Preview':`<iframe style="min-width: 623px !important; width: 100%; height: 100px;" srcdoc="${row.html_code}"></iframe>`,
                                // 'Actions':'<div class="d-flex align-items-center">' +
                                //     `<button btn-id="${row.id}" btn-action="edit" class="ms-2 btn btn-primary btn-action"><i class="fa fa-edit"></i>edit</button>` +
                                //     `<button btn-id="${row.id}" btn-action="delete" class="ms-2 btn btn-danger btn-action"><i class="fa fa-trash"></i>delete</button>` +
                                //     '</div>'
                                // 'n°': (i+1),
                                // 'Name': `<span class="selectable">${row.name}</span>`,
                                // 'URL':row.redirect_url,
                                // 'Preview':`<iframe style="width: 100%; height: 440px;" srcdoc="${row.html_code}"></iframe>`,
                                // 'Organisation':row.organisation.name,
                                // 'Actions': btn, name:
                                // row.name, id: row.id
                            });
                        }
                    }
                    else {
                        let msg = 'Oups ! something went wrong.'
                        this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    setTimeout(() => {
                        this.waitingAPITemplate = false
                    }, 2000);
                })
                .catch(error => {
                    if (error.response) {
                        this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                    }
                    else if (error.request) {
                        let msg = 'The request was made but no response was received. Please check your network.'
                        this.alertString = `${msg}|alert-no|8000::`+Date.now()
                    }
                    else {
                        let msg = 'Oups ! something went wrong.'
                        this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.waitingAPITemplate = false
                })
        },
        connect () {
            /*phishAPIGateway.post('accounts/auth/jwt/create/',
                {
                    email: GO_PHISH_USER,
                    password: GO_PHISH_PASS,
                })
                .then(response => {
                    if(response.status == 200) {
                        const payload = response.data
                        console.log(payload)
                    }
                    else {
                        let msg = "Could not initialized Spectorly Phishing Tool !"
                        this.alertString = `${msg}|alert-no|5000::`+Date.now()
                        this.waitingAPIResponse = false
                    }
                })
                .catch(error => {
                    if (error.response) {
                        let msg = "Could not initialized Spectorly Phishing Tool !"
                        this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    else if (error.request) {
                        let msg = "The request was made but no response was received. Please check your network."
                        this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    else {
                        let msg = "Oups ! something went wrong."
                        this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.waitingAPIResponse = false
                })*/
        },
        update(id,data,page,org,group,template,smtp){
           
           getAPI.put(`phishing/campaign/${id}/`, {
                   
                   "name": data.name,
                   "page": page,
                   "template": template,
                   "smtp": smtp ||'0ad68c13-3ef0-4432-92a3-2c6983e8506b',
                   "groups": group,
                   "send_by_date":data.updated_at,
                   "lauch_date": data.launch_date || "2024-04-12T18:40:00Z",
                   "url": data.url || "http://localhost",
                   "organisation": org,
                   "status":data.status,
                   // "organisation": "69cee3c9-c561-4469-b0ea-f3df9a616592",
                   "is_validated_by_admin": true
               })
                   .then(response => {
                       if(response.status == 200) {
                           this.getAllTemplate()
                           let msg = 'Campagne validé avec succès!'
                           this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                           
                       }
                       else {
                        //    let msg = 'Oups ! something went wrong.'
                        //    this.alertString = `${msg}|alert-no|5000::`+Date.now()
                       }
                       this.exitOrgUpdateModal = Date.now()
                   })
                   .catch(error => {
                       if (error.response) {
                        //    this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
                       }
                       else if (error.request) {
                        //    let msg = 'The request was made but no response was received. Please check your network.'
                        //    this.alertString = `${msg}|alert-no|8000::`+Date.now()
                       }
                       else {
                        //    let msg = 'Oups ! something went wrong.'
                        //    this.alertString = `${msg}|alert-no|5000::`+Date.now()
                       }
                       this.exitOrgUpdateModal = Date.now()
                   })
       },
       getcampanys(id){
            this.waitingAPIResponse = true
            getAPI.get(`micro_learning/campaigns/${id}`, {

            }).then(response=>{
                if(response.status == 200) {
             this.campaignByid = response.data;
             if(this.campaignByid.campaign_type =='email'){
                this.newDomainForm3=true;
             }if(this.campaignByid.campaign_type =='sms'){this.newDomainForm2=true;}
              console.log(this.campagns.page.id);
              let page=this.campagns.page.id;
                let template=this.campagns.template.id;
                // let smtp=data.smtp.id;
                let org=this.campagns.organisation.id;
                let smtp=this.campagns.smtp.id;
                let gp=this.campagns.groups[0].id;
                let group=[];
                group.push(gp);
            
                this.update(id,this.campagns,page,org,group,template,smtp);
                this.waitingAPIResponse = false
            }
            else {
            //   let msg = 'Oups ! something went wrong.'
            //   this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            }).catch(error => {
            if (error.response) {
              this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
            }
            else if (error.request) {
            //   let msg = 'The request was made but no response was received. Please check your network.'
            //   this.alertString = `${msg}|alert-no|8000::`+Date.now()
            }
            else {
            //   let msg = 'Oups ! something went wrong.'
            //   this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
           
          })
        },

        async getcampany(id){
            //this.waitingAPIResponse = true
            await getAPI.get(`micro_learning/campaigns/${id}`, {

            }).then(response=>{
                console.log(response)
                if(response.status == 200) {
                this.campaignByid = response.data;
                this.campaignToUpdate = JSON.parse(JSON.stringify(response.data));
              console.log(this.campagns.page.id);
              let page=this.campagns.page.id;
                let template=this.campagns.template.id;
                // let smtp=data.smtp.id;
                let org=this.campagns.organisation.id;
                let smtp=this.campagns.smtp.id;
                let gp=this.campagns.groups[0].id;
                let group=[];
                group.push(gp);
            
                this.update(id,this.campagns,page,org,group,template,smtp);
                //this.waitingAPIResponse = false
                return true
            }
            else {
            //   let msg = 'Oups ! something went wrong.'
            //   this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            }).catch(error => {
            if (error.response) {
            //   this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
            }
            else if (error.request) {
            //   let msg = 'The request was made but no response was received. Please check your network.'
            //   this.alertString = `${msg}|alert-no|8000::`+Date.now()
            }
            else {
            //   let msg = 'Oups ! something went wrong.'
            //   this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            return false
          })
        },
        callUpdatecampaignForm(data){
        this.newcampaignUpdate=true;
        this.getCallToActionForCampaignss(data);
        console.log(data);
    },
        async getCallToActionForCampaigns (payload) {
            
            if(payload.action_type == 'edit') {
                await this.getcampany(payload.id);
                if (this.campaignByid) {
                    this.micro_learning_id = this.campaignByid.micro_learning.id
                    this.planification_date = this.campaignByid.planification_date
                    if (this.campaignByid.group) {
                        this.newCampaignTarget =  "GROUP"
                        await getAPI.get(`groupes/groupe_by_organisation/${this.campaignByid.group}`).then(res => {
                            console.log(res)
                            if (res.status == 200 && res.data?.data) {
                                this.groupOrgData = res.data.data
                            }
                        })
                    } else if (this.campaignByid.service) {
                        this.newCampaignTarget =  "SERVICE"
                        await getAPI.get(`services/service_by_organisation/${this.campaignByid.service}`).then(res => {
                            console.log(res)
                            if (res.status == 200 && res.data?.data) {
                                this.serviceOrgData = res.data.data
                            }
                        })
                    }
                    this.newcampaignUpdate=true;
                }
                
            }
            // if(payload.action_type == 'validate') {
            //     this.openDeletionCampaignBox = true;
            //     this.tempDeleteID = payload.id;
            //     this.tempDeleteName = payload.name;
            // }
            if(payload.action_type == 'validate') {
                await this.getcampanys(payload.id);
                
                
            }
            /*if(payload.action_type == 'view') {
                let a = document.createElement('a')
                a.href="/campagne-de-phishing/details/dsxxxsxs"+payload.id
                a.click()
                a.remove()
            }*/
        },
        getCallToActionForGroup (payload) {
            if(payload.action_type == 'delete') {
                this.openDeletionBox = true;
                this.tempDeleteID = payload.id;
                this.tempDeleteName = payload.name;
            }
            if(payload.action_type == 'edit') {
                getAPI.get(`groupes/${payload.id}/`, {

}).then(response=>{
    if(response.status == 200) {
 this.selectedGroup = response.data;
  
}
else {
  let msg = 'Oups ! something went wrong.'
  this.alertString = `${msg}|alert-no|5000::`+Date.now()
}
}).catch(error => {
if (error.response) {
  this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
}
else if (error.request) {
  let msg = 'The request was made but no response was received. Please check your network.'
  this.alertString = `${msg}|alert-no|8000::`+Date.now()
}
else {
  let msg = 'Oups ! something went wrong.'
  this.alertString = `${msg}|alert-no|5000::`+Date.now()
}

})

this.openUpdateGroup=true;
                for(var i=0;i<this.selectedGroup;i++){
                    let row= this.selectedGroup[i].user_detail;
                    this.userGroupAll = [
                    {
                        'Nom':row.firstname, 'Prénoms':row.lastname, 'Email':row.email, 'Position':row.position,
                        // 'Actions':'<div class="d-flex align-items-center">' +
                        //     '<button btn-id="12E3" btn-action="delete" class="ms-2 btn btn-danger btn-action"><i class="fa fa-trash"></i>delete</button>' +
                        //     '</div>'
                    },
                    
                ];
            }
                }

                
                /*phishAPIGateway.get(`organisationsss/${payload.id}`,)
                    .then(response => {
                        if(response.status == 200) {
                            let data = response.data
                            this.updateGroup(data);
                        }
                        else {
                            let msg = 'Oups ! something went wrong.'
                            this.alertString = `${msg}|alert-no|5000::`+Date.now()
                        }
                    })
                    .catch(error => {
                        if (error.response) {
                            this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
                        }
                        else if (error.request) {
                            let msg = 'The request was made but no response was received. Please check your network.'
                            this.alertString = `${msg}|alert-no|8000::`+Date.now()
                        }
                        else {
                            let msg = 'Oups ! something went wrong.'
                            this.alertString = `${msg}|alert-no|5000::`+Date.now()
                        }
                    })*/
            
        },
        getCallToActionForUserGroup (payload) {
            if(payload.action_type == 'delete') {
                this.openDeletionBox = true;
                this.tempDeleteID = payload.id;
                this.tempDeleteName = payload.name;
            }
        },
        
       
        getAllGroup () {
            this.waitingAPIResponse = true
            /*phishAPIGateway.get(`test`)
                .then(response => {
                    if(response.status == 200) {
                        let users = response.data.data;
                        this.userAll = []
                        for(let i=0; i<users.length; i++) {
                            let row = users[i]
                            let email = `<a style="color: #175ccc; font-weight: bold;" href="mailto:${row.email}">${row.email}</a>`
                            let btn = `<div class="d-flex align-items-center">`;
                            btn += (row.is_active) ? `<button btn-id="${row.id}" btn-action="deactivate" class="ms-2 btn btn-successc"><i class="fa fa-eye"> COMPTE ACTIF&nbsp;&nbsp;</i></button>` : `<button btn-id="${row.id}" btn-action="activate" class="ms-2 btn cfa-btn-danger btn-action"><i class="fa fa-eye-slash"></i> COMPTE INACTIF</button>`;
                            btn += `</div>`;

                            let progress = `<div class="cfa-progress-bar2 my-4 w-100">
                  <div class="d-flex align-items-center w-100">
                    <div class="position-relative cfa-progress-wrapper w-100" style="background-color: rgb(205, 240, 243); height: 1.6em; border-radius: 3px;">
                      <div class="cfa-progress-line" style="background-color: #06bbc4;width:${40}%; height: 1.6em; border-radius: 3px;'"></div>
                    </div>
                    &nbsp;
                    <span style="min-width: 3em;">${ 40 }%</span>
                  </div>
                </div>`;
                            // classify and add to array
                            this.userAll.push({'n°': (i+1), 'Nom & Prénoms': row.firstname + ' ' + row.lastname, 'Email':email, 'Téléphone':row.phone, 'CyberScore': 0, 'Progression': progress, 'Fin Souscription': 'undefined', 'Actions': btn, name: i, id: row.id})
                        }
                    }
                    else {
                        let msg = 'Oups ! something went wrong.'
                        this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    setTimeout(() => {
                        this.waitingAPIResponse = false
                    }, 2000);
                })
                .catch(error => {
                    if (error.response) {
                        this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                    }
                    else if (error.request) {
                        let msg = 'The request was made but no response was received. Please check your network.'
                        this.alertString = `${msg}|alert-no|8000::`+Date.now()
                    }
                    else {
                        let msg = 'Oups ! something went wrong.'
                        this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.waitingAPIResponse = false
                })*/
        },
        updateGroup (pos) {
            this.updateGroupPayload = pos
            this.openUpdateGroup = true
        },
        confirmDeletion2 () {
            if(this.name_check.toUpperCase() != this.tempDeleteName.toUpperCase()) {
                this.callbackPayload = 'Oups ! Champ incorrecte.' + '::-1::' + Date.now();
            }
            else{
                getAPI.delete(`phishing/campaign/${this.tempDeleteID}`)
            .then(response => {
              if(response.status == 204) {
                this.callbackPayload = ' campaign supprimé avec succès' + '::' + Date.now();
                setTimeout(() => {
                  this.getAllCampaign ()
                }, 1500)
              }
              else {
                let msg = 'Oups ! something went wrong.'
                this.alertString = `${msg}|alert-no|5000::`+Date.now()
                // this.exitRoleModalDel = Date.now()
              }
            })
            .catch(() => {
              this.callbackPayload = 'Oups ! something went wrong.' + '::-1::' + Date.now();
            })
        }
            
        },
        confirmDeletion1 () {
            if(this.name_check.toUpperCase() != this.tempDeleteName.toUpperCase()) {
                this.callbackPayload = 'Oups ! Champ incorrecte.' + '::-1::' + Date.now();
            }
            else{
                /*phishAPIGateway.delete(`organisationszz/${this.tempDeleteID}/`)
                    .then(response => {
                        if(response.status == 204) {
                            this.callbackPayload = 'Groupe supprimé avec succès' + '::' + Date.now();
                            setTimeout(() => {
                                this.getAllGroup()
                            }, 1500)
                        }
                        else {
                            let msg = 'Oups ! something went wrong.'
                            this.alertString = `${msg}|alert-no|5000::`+Date.now()
                            this.exitOrgModalDel = Date.now()
                        }
                    })
                    .catch(() => {
                        this.callbackPayload = 'Oups ! something went wrong.' + '::-1::' + Date.now();
                    })*/
            }
        },
        confirmUpdate () {
            delete this.updateDataPayload.logo
            if(this.updateDataPayload.name != '' && this.updateDataPayload.numSiret != '' && this.updateDataPayload.activity != '' && this.updateDataPayload.adress != '' && this.updateDataPayload.phone != '' && this.updateDataPayload.email != '' && this.updateDataPayload.website != '') {
                console.log('delete')
                /*phishAPIGateway.put(`organisationbbbs/${this.updateDataPayload.id}/`, this.updateDataPayload)
                    .then(response => {
                        if(response.status == 200) {
                            this.getAllGroup()
                            let msg = 'Groupe MAJ avec succès!'
                            this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                            this.exitOrgUpdateModal = Date.now()
                        }
                        else {
                            let msg = 'Oups ! something went wrong.'
                            this.alertString = `${msg}|alert-no|5000::`+Date.now()
                        }
                        this.exitOrgUpdateModal = Date.now()
                    })
                    .catch(error => {
                        if (error.response) {
                            this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
                        }
                        else if (error.request) {
                            let msg = 'The request was made but no response was received. Please check your network.'
                            this.alertString = `${msg}|alert-no|8000::`+Date.now()
                        }
                        else {
                            let msg = 'Oups ! something went wrong.'
                            this.alertString = `${msg}|alert-no|5000::`+Date.now()
                        }
                        this.exitOrgUpdateModal = Date.now()
                    })*/
            }
            else {
                let msg = 'Oups ! Veuillez remplir le champ convenablement.'
                this.alertString = `${msg}|alert-no|5000::`+Date.now()
                this.exitOrgUpdateModal = Date.now()
            }
        },
        confirmCreate () {
            delete this.updateDataPayload.logo
            if(this.updateDataPayload.name != '' && this.updateDataPayload.numSiret != '' && this.updateDataPayload.activity != '' && this.updateDataPayload.adress != '' && this.updateDataPayload.phone != '' && this.updateDataPayload.email != '' && this.updateDataPayload.website != '') {
                console.log('delete')
                /*phishAPIGateway.put(`organisationbbbs/${this.updateDataPayload.id}/`, this.updateDataPayload)
                    .then(response => {
                        if(response.status == 200) {
                            this.getAllGroup()
                            let msg = 'Groupe MAJ avec succès!'
                            this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                            this.exitOrgUpdateModal = Date.now()
                        }
                        else {
                            let msg = 'Oups ! something went wrong.'
                            this.alertString = `${msg}|alert-no|5000::`+Date.now()
                        }
                        this.exitOrgUpdateModal = Date.now()
                    })
                    .catch(error => {
                        if (error.response) {
                            this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
                        }
                        else if (error.request) {
                            let msg = 'The request was made but no response was received. Please check your network.'
                            this.alertString = `${msg}|alert-no|8000::`+Date.now()
                        }
                        else {
                            let msg = 'Oups ! something went wrong.'
                            this.alertString = `${msg}|alert-no|5000::`+Date.now()
                        }
                        this.exitOrgUpdateModal = Date.now()
                    })*/
            }
            else {
                let msg = 'Oups ! Veuillez remplir le champ convenablement.'
                this.alertString = `${msg}|alert-no|5000::`+Date.now()
                this.exitOrgUpdateModal = Date.now()
            }
        },
        newGroup () {
            this.userGroupAll = []
            this.openCreateGroup = true;
        },
        newCampaign () {
            this.newCampaignForm=true;
        },
        newTemplate () {
            this.newTemplateForm=true;
        },
        newLanding () {
            this.newLandingForm=true;
        },
        newSmtp () {
            this.newSmtpForm=true;
        },
        confirmCreateTemplate () {
            if(this.selectedTemplate !== null && this.selectedLanding != null) {
                getAPI.post(`phishing/declaration-page-template/`, {
                    template_id: this.selectedTemplate,
                    landing_page_id: this.selectedLanding,
                    
                })
                    .then(response => {
                        if(response.status == 200 || response.status == 201) {
                            this.getAllDeclaration()
                            let msg = 'Decclaration ajouté avec succès!'
                            this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                            this.exitOrgUpdateModal2 = true
                        }
                        else {
                            let msg = 'Oups ! something went wrong.'
                            this.alertString = `${msg}|alert-no|5000::`+Date.now()
                            this.exitOrgUpdateModal2 = true
                        }
                        //this.exitOrgUpdateModal2 = Date.now()
                        this.exitOrgUpdateModal2 = true
                    })
                    .catch(error => {
                        if (error.response) {
                            this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
                            this.exitOrgUpdateModal2 = true
                        }
                        else if (error.request) {
                            let msg = 'The request was made but no response was received. Please check your network.'
                            this.alertString = `${msg}|alert-no|8000::`+Date.now()
                            this.exitOrgUpdateModal2 = true
                        }
                        else {
                            let msg = 'Oups ! something went wrong.'
                            this.alertString = `${msg}|alert-no|5000::`+Date.now()
                            this.exitOrgUpdateModal2 = true
                        }
                        //this.exitOrgUpdateModal2 = Date.now()
                        this.exitOrgUpdateModal2 = true
                    })
                    this.exitOrgUpdateModal2 = true
            }
            else {
                let msg = 'Oups ! Veuillez remplir le champ convenablement.'
                this.alertString = `${msg}|alert-no|5000::`+Date.now()
                this.exitOrgUpdateModal2 = Date.now()
            }
        },
        confirmCreateLanding  () {
                getAPI.post(`phishing/landing-page/`, {
                    name: this.newLandingName,
                    html_code: this.newLandingHtmlCode,
                    capture_credentials: this.newLandingCapturePrivacy || false,
                    capture_passwords: this.newLandingCapturePass || false,
                    redirect_url: this.newLandingUrl,
                })
                    .then(response => {
                        if(response.status == 200 || response.status == 201) {
                            this.getAllLanding()
                            let msg = 'Landing ajouté avec succès!'
                            this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                            this.exitOrgUpdateModal = Date.now()
                            console.log("Landing créé !")
                            this.getAllLanding();
                        }
                        else {
                            let msg = 'Oups ! something went wrong.'
                            this.alertString = `${msg}|alert-no|5000::`+Date.now()
                        }
                        this.exitOrgUpdateModal = Date.now()
                    })
                    .catch(error => {
                        if (error.response) {
                            this.alertString = `${error.response.data.redirect_url[0]}|alert-no|10000::`+Date.now()
                        }
                        else if (error.request) {
                            let msg = 'The request was made but no response was received. Please check your network.'
                            this.alertString = `${msg}|alert-no|8000::`+Date.now()
                        }
                        else {
                            let msg = 'Oups ! something went wrong.'
                            this.alertString = `${msg}|alert-no|5000::`+Date.now()
                        }
                        this.exitOrgUpdateModal = Date.now()
                    })
        },
        getCallToActionForSmtp (payload) {
            if(payload.action_type == 'editSmtp') {
                this.smtpToUpdate = payload.id;
                getAPI.get(`phishing/sending-profile/${payload.id}`)
                .then(response => {
                    if(response.status === 200) {
                        this.smtpUpdate(response.data);
                    }
                    else {
                        let msg = 'Oups ! something went wrong.'
                        this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    setTimeout(() => {
                        this.waitingAPITemplate = false
                    }, 2000);
                })
                .catch(error => {
                    if (error.response) {
                        this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                    }
                    else if (error.request) {
                        let msg = 'The request was made but no response was received. Please check your network.'
                        this.alertString = `${msg}|alert-no|8000::`+Date.now()
                    }
                    else {
                        let msg = 'Oups ! something went wrong.'
                        this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.waitingAPITemplate = false
                })
            }
            if(payload.action_type == 'deleteSmtp') {
                this.openDeletionCampaignBox2 = true;
                this.smtpToUpdate = payload.id;
            }
        },
        smtpUpdate(pos) {
            this.newSmtpForm2 = true;
            this.smtpData2 = pos
        },
        confirmDeletionSmtp() {
            getAPI.delete(`phishing/sending-profile/${this.smtpToUpdate}`)
                .then(response => {
                    if(response.status == 204) {
                        this.getAllSmtps()
                        let msg = 'Smtp suprimé avec succès!'
                        this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                        this.exitOrgModalDel = Date.now()
                    }
                    else {
                        let msg = 'Oups ! something went wrong.'
                        this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.exitOrgModalDel = Date.now()
                })
                .catch(error => {
                    if (error.response) {
                        this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
                    }
                    else if (error.request) {
                        let msg = 'The request was made but no response was received. Please check your network.'
                        this.alertString = `${msg}|alert-no|8000::`+Date.now()
                    }
                    else {
                        let msg = 'Oups ! something went wrong.'
                        this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.exitOrgModalDel = Date.now()
                })
        },
        confirmUpdateSmtp() {
            if(this.smtpData2.name != null && this.smtpData2.host != null &&  this.smtpData2.from_address != null) {
                getAPI.put(`phishing/sending-profile/${this.smtpData2.id}/`, {
                    name: this.smtpData2.name,
                    host: this.smtpData2.host,
                    username: this.smtpData2.username,
                    password: this.smtpData2.password,
                    from_address: this.smtpData2.from_address,
                })
                    .then(response => {
                        if(response.status == 200 || response.status == 201) {
                            this.getAllSmtps()
                            let msg = 'Smtp mis à jour avec succès!'
                            this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                            this.exitOrgUpdateModal3 = Date.now()
                            console.log("Smtp créé !")
                        }
                        else {
                            let msg = 'Oups ! something went wrong.'
                            this.alertString = `${msg}|alert-no|5000::`+Date.now()
                        }
                        this.exitOrgUpdateModal3 = Date.now()
                    })
                    .catch(error => {
                        if (error.response) {
                            this.alertString = `${error.response.data.redirect_url[0]}|alert-no|10000::`+Date.now()
                        }
                        else if (error.request) {
                            let msg = 'The request was made but no response was received. Please check your network.'
                            this.alertString = `${msg}|alert-no|8000::`+Date.now()
                        }
                        else {
                            let msg = 'Oups ! something went wrong.'
                            this.alertString = `${msg}|alert-no|5000::`+Date.now()
                        }
                        this.exitOrgUpdateModal3 = Date.now()
                    })
            }
            else {
                let msg = 'Oups ! Veuillez remplir le champ convenablement.'
                this.alertString = `${msg}|alert-no|5000::`+Date.now()
                this.exitOrgUpdateModal3 = Date.now()
                console.log("Champ manquant !")
            }
        },
        confirmCreateSmtp () {
            if(this.newSmtpName != null && this.newSmtpHost != null && this.newSmtpUsername != null && this.newSmtpPassword != null && this.newSmtpFromAddress != null) {
                getAPI.post(`phishing/sending-profile/`, {
                    name: this.newSmtpName,
                    host: this.newSmtpHost,
                    username: this.newSmtpUsername,
                    password: this.newSmtpPassword,
                    from_address: this.newSmtpFromAddress,
                })
                    .then(response => {
                        if(response.status == 200 || response.status == 201) {
                            this.getAllSmtps()
                            let msg = 'Smtp créé avec succès!'
                            this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                            this.exitOrgUpdateModal3 = Date.now()
                            console.log("Smtp créé !")
                        }
                        else {
                            let msg = 'Oups ! something went wrong.'
                            this.alertString = `${msg}|alert-no|5000::`+Date.now()
                        }
                        this.exitOrgUpdateModal3 = Date.now()
                    })
                    .catch(error => {
                        if (error.response) {
                            this.alertString = `${error.response.data.redirect_url[0]}|alert-no|10000::`+Date.now()
                        }
                        else if (error.request) {
                            let msg = 'The request was made but no response was received. Please check your network.'
                            this.alertString = `${msg}|alert-no|8000::`+Date.now()
                        }
                        else {
                            let msg = 'Oups ! something went wrong.'
                            this.alertString = `${msg}|alert-no|5000::`+Date.now()
                        }
                        this.exitOrgUpdateModal3 = Date.now()
                    })
            }
            else {
                let msg = 'Oups ! Veuillez remplir le champ convenablement.'
                this.alertString = `${msg}|alert-no|5000::`+Date.now()
                this.exitOrgUpdateModal3 = Date.now()
                console.log("Champ manquant !")
            }
        },
        
     
        getAllCampaign () {
            this.waitingAPIResponse = true
            getAPI.get(`micro_learning/campaigns/`)
                .then(response => {
                    if(response.status == 200) {
                        let groups = response.data.results;
                        console.log("Campagnes: ", response.data.results);
                        this.campaignsAll = []
                        let group;
                        if(groups != []) {
                            this.validationWaiting = this.campaignsAll.length;
                        }
                        for(let i=0; i<groups.length; i++) {
                            if(groups[i].status == "created" || groups[i].status == "partial" || groups[i].status == "unavailable"){
                                this.validationWaiting += 1;
                            }
                            let row = groups[i];
                            if(row.status !== "In Progress" && row.status !== "success" && row.satus !== "failed") {
                                
                                let  btn = `<div class="d-flex align-items-center">`;
                            //   btn += `<button style="background-color:white; border: 1px solid #fff;" btn-id="${row.id}" btn-action="edit" class="ms-2" :disabled=" ${row.status == 'success'}"  ><i class="fa fa-edit " style="color:#90ee90;" ></i>Edit</button>`;
                            //   btn += `${row.campaign_type == 'email' ? `<button style="background-color:white; border: 1px solid #fff;" btn-id="${row.id}" btn-action="validate" class="ms-2" :disabled=" ${row.status == 'success'}" ><i class="fa fa-check " style="color:#90ee90;" ></i></button>` :`<button style="background-color:white; border: 1px solid #fff;" btn-id="${row.id}" btn-action="download" @click="callUpdatecampaignFormss(${row.id})" class="ms-2 :disabled="${row.status == 'In Progress'} || ${row.status == 'success'}"  btn-action"><i class="fa fa-check " style="color:#90ee90;" ></i></button>`}`;
                              btn += `<button btn-id="${row.id}" btn-action="edit" class="ms-2 btn btn-warning btn-action" style="color:white;"><i class="fa fa-edit"></i>Edit</button>`;
                              btn += `<button btn-id="${row.id}" btn-action="validate" class="ms-2 btn btn-success btn-action" style="color:white;"><i class="fa fa-check"></i>Validate</button>`;
                              btn += `</div>`;

                            // let btn = `<div class="d-flex align-items-center">`;
                            // btn += `<a href="/campagne-de-phishing/details/${row.id}" class="disabled"><button btn-id="12E3" btn-action="view" class="ms-2 btn cfa-btn-primary btn-action disabled"><i class="fa fa-area-chart">&nbsp;</i>TABLEAU DE BOARD</button></a>`;
                            // btn += `<button btn-id="12E3" btn-action="delete" class="ms-2 btn btn-danger btn-action"><i class="fa fa-trash"></i></button>`;
                            // btn += `</div>`;

                            if(row.service == null){
                              getAPI.get(`groupes/${row.group}/`, {
                    //   name: this.updateGroupPayload.name,
                    //   organisation: sessionStorage.organisation,
                    //   users: this.selectedUser
                  })
                  .then(response => {
                      if(response.status == 200) {
                        //console.log(response.data);
                        group=response.data.name;
                        console.log("hello",group);
                        this.campaignsAll.push({
                                  'Nom de la campaigne':`<span class="selectable">${row.name}</span>`,
                                  'Type':`<span class="selectable">${row.campaign_type}</span>`,
                                  'Statut':`${row.status == "success" ? `<span class="badge rounded-pill badge-danger cfa-bg-secondary mw-status">succès</span>` : (row.status == "canceled" ? `<span class="badge rounded-pill badge-success cfa-bg-success mw-status" style="background-color:##E7F9F1;color:#14C766;">Terminèe</span>` : `<span class="badge rounded-pill badge-warning cfa-bg-warning mw-status" style="background-color:#FEF6EA;color:#F8B13D;">En attente</span>`)}`,
                                  'Date création': this.convertDate(row.created_at, true),

                                   'Date validation': `${row.validated_at == null ? `<span class="badge rounded-pill badge-danger  mw-status" style="background-color:#FEEAEB;color:#FF3947;">En attente</span>`:(row.validated_at != null ? this.convertDate(row.validated_at, true):`hello`)}`,

                                  'Cible':`<span class="selectable">${group}</span>`,
                                  'Actions': btn,
                                  name: row.name,
                                  id: row.id,
                                  created_at: row.created_at
                              });
                              console.log("g",this.campaignsAll);
                              this.campaignsAll.sort((a, b) => {
                              let dateA = new Date(a.created_at);
                               let dateB = new Date(b.created_at);
                             return dateB - dateA; // For descending order (latest first)
                           });
                           
                        //   this.getAllGroup()
                          //let msg = 'Groupe créé avec succès!'
                          //this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                          //this.exitNewGroupModal = Date.now()
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      //this.exitNewGroupModal = Date.now()
                  })}else{
                    getAPI.get(`services/${row.service}/`, {
                    //   name: this.updateGroupPayload.name,
                    //   organisation: sessionStorage.organisation,
                    //   users: this.selectedUser
                  })
                  .then(response => {
                      if(response.status == 200) {
                        //console.log(response.data);
                        group=response.data.name;
                        console.log("hello",group);
                        this.campaignsAll.push({
                                  'Nom de la campaigne':`<span class="selectable">${row.name}</span>`,
                                  'Type':`<span class="selectable">${row.campaign_type}</span>`,
                                  'Statut':`${row.status == "success" ? `<span class="badge rounded-pill badge-danger cfa-bg-secondary mw-status">succès</span>` : (row.status == "failed" ? `<span class="badge rounded-pill badge-success cfa-bg-success mw-status" style="background-color:##E7F9F1;color:#14C766;">Terminèe</span>` : `<span class="badge rounded-pill badge-warning cfa-bg-warning mw-status" style="background-color:#FEF6EA;color:#F8B13D;">En attente</span>`)}`,
                                  'Date création': this.convertDate(row.created_at, true),
                                  'Date validation': `${row.validated_at == null ? `<span class="badge rounded-pill badge-danger  mw-status" style="background-color:#FEEAEB;color:#FF3947;">En attente</span>`:(row.validated_at != null ? this.convertDate(row.validated_at, true):`hello`)}`,
                                  'Cible':`<span class="selectable">${group}</span>`,
                                  'Actions': btn,
                                  name: row.name,
                                  id: row.id,
                                  created_at: row.created_at
                              });
                              console.log("g",this.campaignsAll);
                              this.campaignsAll.sort((a, b) => {
                              let dateA = new Date(a.created_at);
                               let dateB = new Date(b.created_at);
                             return dateB - dateA; // For descending order (latest first)
                           });
                           
                        //   this.getAllGroup()
                          //let msg = 'Groupe créé avec succès!'
                          //this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                          //this.exitNewGroupModal = Date.now()
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      //this.exitNewGroupModal = Date.now()
                  })
                  }
                          }
                        }
                        for(let i=0; i<groups.length; i++) {
                            let row = groups[i];
                            if(row.is_validated_by_admin == true && row.status == "In Progress") {
                                this.inProgress += 1

                            // let btn = `<div class="d-flex align-items-center">`;
                            // btn += `<a href="/campagne-de-phishing/details/${row.id}" class="disabled"><button btn-id="${row.id}" btn-action="view" class="ms-2 btn cfa-btn-primary btn-action disabled"><i class="fa fa-area-chart">&nbsp;</i>TABLEAU DE BOARD</button></a>`;
                            // btn += `<button btn-id="${row.id}" btn-action="delete" class="ms-2 btn btn-danger btn-action"><i class="fa fa-trash"></i>delete</button>`;
                            // btn += `</div>`;

                            // let btn = `<div class="d-flex align-items-center">`;
                            // btn += `<a href="/campagne-de-phishing/details/${row.id}" class="disabled"><button btn-id="12E3" btn-action="view" class="ms-2 btn cfa-btn-primary btn-action disabled"><i class="fa fa-area-chart">&nbsp;</i>TABLEAU DE BOARD</button></a>`;
                            // btn += `<button btn-id="12E3" btn-action="delete" class="ms-2 btn btn-danger btn-action"><i class="fa fa-trash"></i></button>`;
                            // btn += `</div>`;

                            if(row.service == null){
                              getAPI.get(`groupes/${row.group}/`, {
                    //   name: this.updateGroupPayload.name,
                    //   organisation: sessionStorage.organisation,
                    //   users: this.selectedUser
                  })
                  .then(response => {
                      if(response.status == 200) {
                        //console.log(response.data);
                        group=response.data.name;
                        console.log("hello",group);
                        this.campaignsAll2.push({
                                  'Nom de la campaigne':`<span class="selectable">${row.name}</span>`,
                                  'Type':`<span class="selectable">${row.campaign_type}</span>`,
                                  'Statut':`${row.status == "success" ? `<span class="badge rounded-pill badge-danger cfa-bg-secondary mw-status">succès</span>` : (row.status == "canceled" ? `<span class="badge rounded-pill badge-success cfa-bg-success mw-status" style="background-color:##E7F9F1;color:#14C766;">Terminèe</span>` : `<span class="badge rounded-pill badge-warning cfa-bg-warning mw-status" style="background-color:#FEF6EA;color:#F8B13D;">En cours</span>`)}`,
                                  'Date création': this.convertDate(row.created_at, true),

                                   'Date validation': `${row.validated_at == null ? `<span class="badge rounded-pill badge-danger  mw-status" style="background-color:#FEEAEB;color:#FF3947;">En attente</span>`:(row.validated_at != null ? this.convertDate(row.validated_at, true):`hello`)}`,

                                  'Cible':`<span class="selectable">${group}</span>`,
                                  
                                  name: row.name,
                                  id: row.id,
                                  created_at: row.created_at
                              });
                              console.log("g",this.campaignsAll2);
                              this.campaignsAll2.sort((a, b) => {
                              let dateA = new Date(a.created_at);
                               let dateB = new Date(b.created_at);
                             return dateB - dateA; // For descending order (latest first)
                           });
                           
                        //   this.getAllGroup()
                          //let msg = 'Groupe créé avec succès!'
                          //this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                          //this.exitNewGroupModal = Date.now()
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      //this.exitNewGroupModal = Date.now()
                  })}else{
                    getAPI.get(`services/${row.service}/`, {
                    //   name: this.updateGroupPayload.name,
                    //   organisation: sessionStorage.organisation,
                    //   users: this.selectedUser
                  })
                  .then(response => {
                      if(response.status == 200) {
                        //console.log(response.data);
                        group=response.data.name;
                        console.log("hello",group);
                        this.campaignsAll2.push({
                                  'Nom de la campaigne':`<span class="selectable">${row.name}</span>`,
                                  'Type':`<span class="selectable">${row.campaign_type}</span>`,
                                  'Statut':`${row.status == "success" ? `<span class="badge rounded-pill badge-danger cfa-bg-secondary mw-status">succès</span>` : (row.status == "failed" ? `<span class="badge rounded-pill badge-success cfa-bg-success mw-status" style="background-color:##E7F9F1;color:#14C766;">Terminèe</span>` : `<span class="badge rounded-pill badge-warning cfa-bg-warning mw-status" style="background-color:#FEF6EA;color:#F8B13D;">En cours</span>`)}`,
                                  'Date création': this.convertDate(row.created_at, true),
                                  'Date validation': `${row.validated_at == null ? `<span class="badge rounded-pill badge-danger  mw-status" style="background-color:#FEEAEB;color:#FF3947;">En attente</span>`:(row.validated_at != null ? this.convertDate(row.validated_at, true):`hello`)}`,
                                  'Cible':`<span class="selectable">${group}</span>`,
                                 
                                  name: row.name,
                                  id: row.id,
                                  created_at: row.created_at
                              });
                              console.log("g",this.campaignsAll2);
                              this.campaignsAll2.sort((a, b) => {
                              let dateA = new Date(a.created_at);
                               let dateB = new Date(b.created_at);
                             return dateB - dateA; // For descending order (latest first)
                           });
                           
                        //   this.getAllGroup()
                          //let msg = 'Groupe créé avec succès!'
                          //this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                          //this.exitNewGroupModal = Date.now()
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      //this.exitNewGroupModal = Date.now()
                  })
                  }
                          }
                        }

                        for(let i=0; i<groups.length; i++) {
                            let row = groups[i];
                            if(row.status == "success") {
                                this.archived += 1

                            // let btn = `<div class="d-flex align-items-center">`;
                            // btn += `<a href="/campagne-de-phishing/details/${row.id}" class="disabled"><button btn-id="12E3" btn-action="view" class="ms-2 btn cfa-btn-primary btn-action disabled"><i class="fa fa-area-chart">&nbsp;</i>TABLEAU DE BOARD</button></a>`;
                            // btn += `<button btn-id="12E3" btn-action="delete" class="ms-2 btn btn-danger btn-action"><i class="fa fa-trash"></i></button>`;
                            // btn += `</div>`;

                            if(row.service == null){
                              getAPI.get(`groupes/${row.group}/`, {
                    //   name: this.updateGroupPayload.name,
                    //   organisation: sessionStorage.organisation,
                    //   users: this.selectedUser
                  })
                  .then(response => {
                      if(response.status == 200) {
                        //console.log(response.data);
                        group=response.data.name;
                        console.log("hello",group);
                        this.campaignsAll3.push({
                                  'Nom de la campaigne':`<span class="selectable">${row.name}</span>`,
                                  'Type':`<span class="selectable">${row.campaign_type}</span>`,
                                  'Statut':`${row.status == "success" ? `<span class="badge rounded-pill badge-danger cfa-bg-secondary mw-status">succès</span>` : (row.status == "canceled" ? `<span class="badge rounded-pill badge-success cfa-bg-success mw-status" style="background-color:##E7F9F1;color:#14C766;">Terminèe</span>` : `<span class="badge rounded-pill badge-warning cfa-bg-warning mw-status" style="background-color:#FEF6EA;color:#F8B13D;">En cours</span>`)}`,
                                  'Date création': this.convertDate(row.created_at, true),

                                   'Date validation': `${row.validated_at == null ? `<span class="badge rounded-pill badge-danger  mw-status" style="background-color:#FEEAEB;color:#FF3947;">En attente</span>`:(row.validated_at != null ? this.convertDate(row.validated_at, true):`hello`)}`,

                                  'Cible':`<span class="selectable">${group}</span>`,
                                  
                                  name: row.name,
                                  id: row.id,
                                  created_at: row.created_at
                              });
                              console.log("g",this.campaignsAll3);
                              this.campaignsAll3.sort((a, b) => {
                              let dateA = new Date(a.created_at);
                               let dateB = new Date(b.created_at);
                             return dateB - dateA; // For descending order (latest first)
                           });
                           
                        //   this.getAllGroup()
                          //let msg = 'Groupe créé avec succès!'
                          //this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                          //this.exitNewGroupModal = Date.now()
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      //this.exitNewGroupModal = Date.now()
                  })}else{
                    getAPI.get(`services/${row.service}/`, {
                    //   name: this.updateGroupPayload.name,
                    //   organisation: sessionStorage.organisation,
                    //   users: this.selectedUser
                  })
                  .then(response => {
                      if(response.status == 200) {
                        //console.log(response.data);
                        group=response.data.name;
                        console.log("hello",group);
                        this.campaignsAll3.push({
                                  'Nom de la campaigne':`<span class="selectable">${row.name}</span>`,
                                  'Type':`<span class="selectable">${row.campaign_type}</span>`,
                                  'Statut':`${row.status == "success" ? `<span class="badge rounded-pill badge-danger cfa-bg-secondary mw-status">succès</span>` : (row.status == "failed" ? `<span class="badge rounded-pill badge-success cfa-bg-success mw-status" style="background-color:##E7F9F1;color:#14C766;">Terminèe</span>` : `<span class="badge rounded-pill badge-warning cfa-bg-warning mw-status" style="background-color:#FEF6EA;color:#F8B13D;">En cours</span>`)}`,
                                  'Date création': this.convertDate(row.created_at, true),
                                  'Date validation': `${row.validated_at == null ? `<span class="badge rounded-pill badge-danger  mw-status" style="background-color:#FEEAEB;color:#FF3947;">En attente</span>`:(row.validated_at != null ? this.convertDate(row.validated_at, true):`hello`)}`,
                                  'Cible':`<span class="selectable">${group}</span>`,
                                  
                                  name: row.name,
                                  id: row.id,
                                  created_at: row.created_at
                              });
                              console.log("g",this.campaignsAll3);
                              this.campaignsAll3.sort((a, b) => {
                              let dateA = new Date(a.created_at);
                               let dateB = new Date(b.created_at);
                             return dateB - dateA; // For descending order (latest first)
                           });
                           
                        //   this.getAllGroup()
                          //let msg = 'Groupe créé avec succès!'
                          //this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                          //this.exitNewGroupModal = Date.now()
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      //this.exitNewGroupModal = Date.now()
                  })
                  }
                          }
                        }
                    }
                    else {
                        let msg = 'Oups ! something went wrong.'
                        this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    setTimeout(() => {
                        this.waitingAPIResponse = false
                    }, 2000);
                })
                .catch(error => {
                    if (error.response) {
                        this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                    }
                    else if (error.request) {
                        let msg = 'The request was made but no response was received. Please check your network.'
                        this.alertString = `${msg}|alert-no|8000::`+Date.now()
                    }
                    else {
                        let msg = 'Oups ! something went wrong.'
                        this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.waitingAPIResponse = false
                })
            /*
            this.campaignsAll = [
                {
                    'n°':'1',
                    'Intitutlé':'SMShing at StartupGrind - wave1',
                    'Statut':"<button class='btn textt-warning disable status_audit' style='min-width: 8em;'><a href=\"#\">EN COURS</a></button>",
                    'Type': 'PHISHING',
                    'Date création': '2013-02-12, 12:23',
                    'Actions':'<div class="d-flex align-items-center">' +
                        '<a href="/campagne-de-phishing/details/dsxxxsxs"><button btn-id="12E3" btn-action="view" class="ms-2 btn cfa-btn-light btn-action"><i class="fa fa-area-chart"> DASHBOARD&nbsp;</i></button></a>' +
                        '<button btn-id="12E3" btn-action="delete" class="ms-2 btn btn-danger btn-action"><i class="fa fa-trash"></i></button>' +
                        '</div>'
                },
                {
                    'n°':'1',
                    'Intitutlé':'SMShing at StartupGrind - wave1',
                    'Statut':"<button class='btn textt-warning disable status_audit' style='min-width: 8em;'><a href=\"#\">EN ATTENTE</a></button>",
                    'Type': 'SMSHING',
                    'Date création': '2013-02-12, 12:23',
                    'Actions':'<div class="d-flex align-items-center">' +
                        '<a href="/campagne-de-phishing/details/dsxxxsxs"><button btn-id="12E3" btn-action="view" class="ms-2 btn cfa-btn-light btn-action"><i class="fa fa-area-chart"> DASHBOARD&nbsp;</i></button></a>' +
                        '<button btn-id="12E3" btn-action="delete" class="ms-2 btn btn-danger btn-action"><i class="fa fa-trash"></i></button>' +
                        '</div>'
                }
            ]*/
        },
        updateData2 (pos) {
            this.templateUpdateData = pos
            this.newTemplateForm2 = true
            console.log("Buhaha: ", this.templateUpdateData)
          },
        updateData3 (pos) {
            this.openDeletionBox2 = true;
            this.templateId = pos
        },
        deleteTemplate() {
            getAPI.delete(`phishing/declaration-page-template/${this.templateToDelete}/`)
                .then(response => {
                    if(response.status == 204) {
                        this.getAllDeclaration()
                        let msg = 'Declaration suprimé avec succès!'
                        this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                        this.exitOrgModalDel = Date.now()
                    }
                    else {
                        let msg = 'Oups ! something went wrong.'
                        this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.exitOrgModalDel = Date.now()
                })
                .catch(error => {
                    if (error.response) {
                        this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
                    }
                    else if (error.request) {
                        let msg = 'The request was made but no response was received. Please check your network.'
                        this.alertString = `${msg}|alert-no|8000::`+Date.now()
                    }
                    else {
                        let msg = 'Oups ! something went wrong.'
                        this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.exitOrgModalDel = Date.now()
                })
        },
         getCallToActionForDeclaration (payload) {
            if(payload.action_type === 'delete') {
                this.templateToDelete = payload.id
                this.updateData3(payload);
            }
            if(payload.action_type === 'edit') {
                getAPI.get(`phishing/declaration-page-template/${payload.id}/`)
                  .then(response => {
                    if(response.status == 200) {
                        this.declarationData = response.data
                        this.updateData2(this.templateData);
                    }
                    else {
                      let msg = 'Oups ! something went wrong.'
                      this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    setTimeout(() => {
                      this.waitingAPITemplate = false
                    }, 2000);
                  })
                  .catch(error => {
                    if (error.response) {
                      this.alertString = `Aucun template disponible |alert-no|10000::`+Date.now()
                    }
                    else if (error.request) {
                      let msg = 'The request was made but no response was received. Please check your network.'
                      this.alertString = `${msg}|alert-no|8000::`+Date.now()
                    }
                    else {
                      let msg = 'Oups ! something went wrong.'
                      this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.waitingAPITemplate = false
                })
            }
        },
        getCallToActionForTemplate (payload) {
            if(payload.action_type === 'delete') {
                this.templateToDelete = payload.id
                this.updateData3(payload);
            }
            if(payload.action_type === 'edit') {
                getAPI.get(`phishing/templates/${payload.id}/`)
                  .then(response => {
                    if(response.status == 200) {
                        this.templateData = response.data
                        this.updateData2(this.templateData);
                    }
                    else {
                      let msg = 'Oups ! something went wrong.'
                      this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    setTimeout(() => {
                      this.waitingAPITemplate = false
                    }, 2000);
                  })
                  .catch(error => {
                    if (error.response) {
                      this.alertString = `Aucun template disponible |alert-no|10000::`+Date.now()
                    }
                    else if (error.request) {
                      let msg = 'The request was made but no response was received. Please check your network.'
                      this.alertString = `${msg}|alert-no|8000::`+Date.now()
                    }
                    else {
                      let msg = 'Oups ! something went wrong.'
                      this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.waitingAPITemplate = false
                })
            }
        },
        confirmUpdateTemplate() {
            getAPI.patch(`phishing/declaration-page-template/${this.declarationData.id}/`, {
                landing_page_id: this.declarationData.landing_page_id,
                template_id: this.declarationData.template_id,
                
            })
              .then(response => {
                if(response.status == 200) {
                    this.getAllDeclaration()
                    let msg = 'Declaration modifié avec succès!'
                    this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                    this.exitOrgUpdateModal3 = true
                }
                else {
                  let msg = 'Oups ! something went wrong.'
                  this.alertString = `${msg}|alert-no|5000::`+Date.now()
                  this.exitOrgUpdateModal3 = true
                }
                setTimeout(() => {
                  this.waitingAPITemplate = false
                }, 2000);
              })
              .catch(error => {
                if (error.response) {
                  this.alertString = `Aucun template disponible |alert-no|10000::`+Date.now()
                  this.exitOrgUpdateModal3 = true
                }
                else if (error.request) {
                  let msg = 'The request was made but no response was received. Please check your network.'
                  this.alertString = `${msg}|alert-no|8000::`+Date.now()
                  this.exitOrgUpdateModal3 = true
                }
                else {
                  let msg = 'Oups ! something went wrong.'
                  this.alertString = `${msg}|alert-no|5000::`+Date.now()
                  this.exitOrgUpdateModal3 = true
                }
                this.exitOrgUpdateModal3 = true
            })
        },
        async getWebContent() {
            let url = this.urlWeb;

            if (!this.isValidUrl(url)) {
                let msg = "Cet URL n'est pas valide"
                this.alertString = `${msg}|alert-no|5000::`+Date.now()
                return;
            }

            try {
                const response = await axios.get(url, {headers: {'Access-Control-Allow-Origin': '*'}});
                console.log(response)
                this.newTemplateForm = response.data;
            }
            catch (error) {
                console.error('Error retrieving web content:', error);
            }
        },
        convertDate(dateString, dateOnly=false) {
            const date = new Date(dateString);
            const formattedDate = date.toLocaleDateString();
            const formattedTime = date.toLocaleTimeString();
            if(!dateOnly) {
                return `<b>${formattedDate} à ${formattedTime}</b>`;
            }
            return `<b>${formattedDate}</b>`;
        },
        getIframeContent(url) {
            return new Promise((resolve, reject) => {
                const iframe = document.createElement('iframe');
                iframe.style.display = 'none';

                const handleLoad = () => {
                    try {
                        const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
                        const htmlContent = iframeDocument.documentElement.outerHTML;
                        resolve(htmlContent);
                    } catch (error) {
                        reject(error);
                    } finally {
                        document.body.removeChild(iframe);
                    }
                };

                iframe.addEventListener('load', handleLoad);
                iframe.src = url;

                document.body.appendChild(iframe);
            });
        },
        getWebContent1() {
            let url = this.urlWeb;

            if (!this.isValidUrl(url)) {
                let msg = "Cet URL n'est pas valide"
                this.alertString = `${msg}|alert-no|5000::`+Date.now()
                return;
            }

            this.getIframeContent(url)
                .then((htmlContent) => {
                    console.log(htmlContent);
                })
                .catch((error) => {
                    console.error('Error retrieving iframe content:', error);
                });
        },
        isValidUrl(url) {
            const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
            return urlRegex.test(url);
        }
    },
    computed: {
          
          nextButtonOptionss() {
              return (this.currentTabIndex === 33)
                  ? {
                      text: 'Créer la campagne',
                      icon: 'check',
                      hideIcon: true, // default false but selected for sample
                      hideText: false, // default false but selected for sample
                      disabled: false,
                  }
                  : ((this.currentTabIndex === 0 && (this.campaignByid.name == null || this.campaignByid.campaign_type === 'default' )) ?
                      {
                          disabled: true,
                          text: "Suivant",
                      }
                      : ((this.currentTabIndex === 1 && (this.campaignByid.campaign_type == null)) ?
                          {
                              disabled: true,
                              text: "Suivant",
                          }
                          : ((this.currentTabIndex === 2 && (this.newCampaignTarget == null)) ?
                              {
                                  disabled: true,
                                  text: "Suivant",
                              }
                              : ((this.currentTabIndex === 3 && (this.newCampaignTarget == null)) ?
                                  {
                                      disabled: true,
                                      text: "Suivant",
                                  }
                                  : 
                                  {
                                          disabled: false,
                                          text: "Suivant",
                                  })
                              )
                          )
                      );
          },
          
          prevButtonOptionss() {
              return {
                  disabled: false,
                  text: "Précédent"
              };
          },
          doneButtonOptions() {
              return (this.newCampaignTarget == 'default') ? {
                  disabled: true,
                  text: "Terminé"
              }: {
                  disabled: false,
                  text: "Terminé"
              };
          },
      },
    mounted () {
        this.getAllTemplate();
        this.getAllOrgs();
        this.getAllDeclaration();
        this.getAllLanding();
        this. getAllSmsProvider();
        this.getAllCampaign();
        this.getAllGroups();
        this.checkCampaigntarget();
        this.getAllService ();
        this.getAllMicroLearning ();
        /*if(sessionStorage.phish_token == null) {
            this.connect();
        }
        this.getAllGroup();
        this.getAllCampaign();*/
        this.getAllDeclaration();
        this.getAllSmtps();
    }
}
</script>

<style scoped>
.cfa-page{}
.case{
    background-color: #cef1f3;
    border:  2px solid #cef1f3;
    padding: 5px;
}
.cfa-block{
    background-color: #fff;
    border-radius: 0.5em;
    padding: .5em 1em;
    box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
    -webkit-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
    -moz-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
    /* border: 1px solid transparent;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); */
}

/* TAB STYLE */
.tab-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.tab-header ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}
.tab-header ul li {
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #333;
    border: 1px solid #ccc;
    border-bottom: none;
    border-radius: 5px 5px 0 0;
}
.tab-header ul li.active {
    background-color: #333;
    color: #fff;
}
.tab-body {
    border: 1px solid #ccc;
    border-radius: 0 .5em .5em .5em;
    min-height: 10em;
}
.gFolder-container{
    background-color: #cef1f3;
    border-radius: 0.5em;
    height: 4.3em;
    width: 4.6em;
    margin-right: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
}
.gBlock{
    align-items: center;
    justify-content: start;
    border-radius: 0.6em;
    padding-left: 2em;
    padding-right: 2em;
    height: 8em !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}
.gBlock div:last-of-type p{
    margin: 0;
}
.gBlock div:last-of-type p:first-of-type{
    font-weight: bold;
    font-size: 2em;
}
</style>
<style scoped lang="css">
@import "~vue2-editor/dist/vue2-editor.css";

/* Import the Quill styles you want */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
</style>