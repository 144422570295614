export function handleApiErrors(error) {
    console.error("API Error:", error);
  
    if (error.response) {
      // Backend responded with an error status
      const { status, data } = error.response;
  
      // Handle different HTTP status codes
      if (status === 400) {
        let errorMessages = Object.values(data).flat().join("\n");
        return `${errorMessages} |alert-no|10000::` + Date.now();
      } 
      else if (status === 401) {
        return `Unauthorized! Please log in again. |alert-no|8000::` + Date.now();
      } 
      else if (status === 403) {
        return `Forbidden! You don't have permission for this action. |alert-no|8000::` + Date.now();
      } 
      else if (status === 404) {
        return `Resource not found! |alert-no|8000::` + Date.now();
      } 
      else if (status >= 500) {
        return `Server error! Please try again later. |alert-no|8000::` + Date.now();
      } 
      else {
        return `Something went wrong. Please try again. |alert-no|5000::` + Date.now();
      }
    } 
    else if (error.request) {
      // Request made but no response received
      return `No response from server. Please check your network connection. |alert-no|8000::` + Date.now();
    } 
    else {
      // Something else happened while setting up the request
      return `Unexpected error occurred. Please try again. |alert-no|5000::` + Date.now();
    }
  }