<template>
    <div :id="uid" :style="(filterMode == 'ALL') ? 'display: block' : ((started == true) ? 'display: block' : 'display: none')" :class="(viewMode == 'GROUP') ? cssClass : 'cfa-module col-12 mb-4'">
      <div :id="uid+'-content'" :class="(viewMode == 'GROUP') ? 'p-3 bg-white cfa-course-module-inner cfa-module-shadow' : 'p-3 bg-white cfa-course-module-inner d-flex flex-row cfa-module-shadow'">
        <div>
          <div class="cfa-module-image position-relative">
            <img :src="preview" alt="cfa module course image"/>
            <button style="text-transform: uppercase;" class="gType btn btn-discret cfa-btn-primary">{{type}}</button>
          </div>
        </div>
        <div :class="(viewMode == 'GROUP') ? '' : 'ms-3 w-100'">
          <div class="py-3">
            <button class="btn btn-discret cfa-btn-primary">
              <img width="10" src="../../../assets/Icons/All_Icon/Vector-7.png" alt="cfa assets"/>
              <span class="label-c">{{label}}</span>
            </button>
          </div>
          <div class="py-2">
            <h2 @click="selectText" style="height: 1.2em; font-size: 1.1em; overflow: hidden;"><strong>{{title}}</strong></h2>
          </div>
          <div class="py-2 pb-3" style="height: 5em; overflow: hidden;" v-html="description"></div>
          <div class="py-2 pt-4">
            <c-f-a-progress :percent="(finished) ? 100 : percent" :active="started"></c-f-a-progress>
          </div>
          <div class="py-2 pb-0 d-flex align-items-center justify-content-between">
            <div class="">
              <img width="15" src="../../../assets/img/png/timer.png"/>
              {{max__horaire}}
            </div>
            <div>
              <a :href="`/management-board/micro-learning-details/${label.toLowerCase()}/${id}`">
                <button class="btn cfa-btn-light-primary mx-1" title="Apperçu">
                  <i class="fa fa-open"></i>APPERÇU
                </button>
              </a>
              <button @click="updateData" class="btn cfa-btn-primary mx-1" title="Modifier">
                <i class="fa fa-edit"></i>
              </button>
              <button @click="deleteData" class="btn cfa-btn-danger mx-1" title="Supprimer">
                <i class="fa fa-trash"></i>
              </button>
              <!-- <button @click="publishModule" :class="{'btn cfa-btn-gold mx-1': available, 'btn cfa-btn-green mx-1': !available}" title="Publier">
                <i :class="{'fa fa-eye': available, 'fa fa-eye-slash': !available}"></i>
              </button> -->
            </div>
          </div>
        </div>
      </div>

      <!--deletion modal-->
      <scalable-modal @callback="tryDeleteModule" :notifyTaskDone="callbackPayload" :uid="uid+'-modal'" @close="openDeletionBox=false" :open="openDeletionBox" :advancedControl="true" validationButtonText="Supprimer" exitButtonText="Annuler" displayMode="flex" :style="'width: 30em; height: 22em;'">
        <template v-slot:content>
          <p>Etes vous sûr de vouloir supprimer ce module? <span style="color: red;">Cette Action est irreversible</span></p>
          <div class="col-12 pl-0">
            <div class="form-group py-3 pe-0 pe-md-2">
              <p class="mb-2"><strong>Entrez l'intitulé du module pour confirmation</strong></p>
              <input class="form-control" type="texte" autocomplete="new-password" v-model="name_check">
            </div>
          </div>
        </template>
      </scalable-modal>

      <!--update modal-->
      <scalable-modal @callback="updateThisModule" :break="exitUpdateModal" :uid="uid + '-update-modal'" @close="openUpdateBox=false" :open="openUpdateBox" :advancedControl="true" validationButtonText="Mettre à jour" exitButtonText="Annuler" :style="'width: 75%; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
        <template v-slot:content>
          <!-- blo1 -->
          <div class="row w-100">
            <div class="col-8">
              <!--<div class="mt-2">
                <modern-input @inputChange="(e)=>{this.connect_email = e}" placeholder="Votre Adresse email" errorText="Champ requis" uid="cfa-username-bloc" type="email" css></modern-input>
              </div>-->
              <div class="form-group py-2 pe-0 pe-md-2 ">
                  <p class="mb-2"><strong>TITRE</strong></p>
                  <div class="d-flex flex-row">
                    <input v-model="module_title" class="form-control" type="text" placeholder="entrez le titre">
                  </div>
                </div>
              <div class="mt-2">
                <p class="mb-2"><strong>DESRIPTION</strong></p>
                <textarea v-model="module_description" class="descriptionArea"></textarea>
              </div>
            </div>
            <div class="col-4 pe-0">
              <div>
                <div class="form-group py-2 pe-0 pe-md-2 ">
                  <p class="mb-2"><strong>MICRO LEARNING URL</strong></p>
                  <div class="d-flex flex-row">
                    <input v-model="module_microlearning_url" class="form-control" type="text" placeholder="entrez l'URL du micro-apprentissage">
                  </div>
                </div>
                <div class="form-group py-3 pe-0 pe-md-2 ">
                  <p class="mb-2"><strong>DURÉE</strong></p>
                  <div class="d-flex flex-row">
                    <input v-model="module_time" class="form-control" type="number" placeholder="(minutes)">
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div class="row w-100 mt-3">
            <div class="form-group py-3 pe-0 pe-md-2 ">
              <p class="mb-2"><strong>IMAGE DE COUVERTURE</strong></p>
              <image-input @image-loaded="onImageLoaded"/>
            </div>
          </div>
  
          <div class="row w-100 mt-3">
            <div class="form-group py-3 pe-0 pe-md-2 ">
              <p class="mb-2"><strong>MESSAGE E-MAIL PRINCIPAL</strong></p>
              <div class="d-flex flex-row">
                <input v-model="module_top_message" class="form-control" type="text" placeholder="entrez le premier e-mail">
              </div>
            </div>
          </div>
  
          <div class="row w-100 mt-3">
            <div class="form-group py-3 pe-0 pe-md-2 ">
              <p class="mb-2"><strong>MESSAGE E-MAIL EN BAS</strong></p>
              <div class="d-flex flex-row">
                <input v-model="module_bottom_message" class="form-control" type="text" placeholder="entrez le message électronique en bas">
              </div>
            </div>
          </div>

          <div class="row w-100 mt-3">
            <div class="form-group py-3 pe-0 pe-md-2 ">
              <p class="mb-2"><strong>SMS MESSAGE</strong></p>
              <div class="d-flex flex-row mb-2">
                <input v-model="module_sms_message" class="form-control" type="text" placeholder="saisir un SMS">
              </div>
            </div>
          </div>
          <div class="row w-100 mt-3">
            <div class="mt-2">
                <p class="mb-2"><strong>IM MESSAGE</strong></p>
                <textarea v-model="module_im_message" class="descriptionArea"></textarea>
              </div>
          </div>
        </template>
      </scalable-modal>

      <!--alert box-->
      <transition name="fadeY" appear mode="out-in">
        <alert v-if="alertMe" :status="gAlertType" :message="gAlertMessage"></alert>
      </transition>
    </div>
</template>

<script>
import CFAProgress from '@/components/shared/progress/CFAProgress.vue'
import ScalableModal from '@/components/shared/modal/ScalableModal.vue'
import { getAPI } from '@/axios-api.js'
import ImageInput from '@/components/shared/input/ImageInput.vue'
import Alert from '@/components/shared/alert/Alert.vue'

export default {
  name: 'CourseCard',
  components: {
    CFAProgress,
    ScalableModal,
    ImageInput,
    Alert
  },
  props: {
    uid: {
      type: String,
      default: 'cfa-course-01'
    },
    preview: {
      type: String,
      default: 'https://picsum.photos/300/201'
    },
    label: {
      type: String,
      default: 'Module 1'
    },
    type: {
      type: String,
      default: 'FORENSIC'
    },
    title: {
      type: String,
      default: 'Sécurité du post de travail.'
    },
    description: {
      type: String,
      default: 'lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem'
    },
    started: {
      type: Boolean,
      default: true
    },
    finished: {
      type: Boolean,
      default: false
    },
    max__horaire: {
      type: String,
      default: '22'
    },
    viewMode: {
      type: String,
      default: 'GROUP'
    },
    filterMode: {
      type: String,
      default: 'ALL'
    },
    cssClass: {
      type: String,
      default: 'cfa-module col-12 col-md-4 mb-4'
    },
    percent: {
      type: Number,
      default: 0
    },
    id: {
      type: String,
      default: null
    },
    published: {
      type: Boolean,
      default: false
    },
    tags: {
      type: Object
    },
    data: {
      type: Object
    },
  },
  data () {
    return {
      openDeletionBox: false,
      available: this.published,
      callbackPayload: null,
      name_check: '',
      openUpdateBox: false,
      exitUpdateModal: null,

      // module_tagSelect1: '',
      // module_tagSelect2: '',
      // module_tagSelect3: '',
      // module_target: this.type,
      // module_content: this.data.link,
      // module_video: this.data.module_video,
      module_title: this.data.title,
      module_description: this.data.description,
      module_sms_message: this.data.sms_message,
      module_time: this.timeToSeconds(this.data.duration),
      module_top_message:this.data.top_email_message,
      module_bottom_message:this.data.bottom_email_message,
      module_im_message: this.data.im_message,
      module_microlearning_url:this.data.micro_learning_url,
      module_image_cover: null,

      alertMe: false,
      gAlertMessage: '',
      gAlertType: '',
    }
  },
  methods: {
    displayError(error, type='alert-ok', time=5000) {
      this.gAlertMessage = error
      this.gAlertType = type
      this.alertMe = true
      setTimeout(() => {
        this.alertMe = false
      }, time);
    },
    updateData () {
      this.openUpdateBox = true
    },
    deleteData () {
      this.openDeletionBox = true
    },
    publishModule () {
      getAPI.patch(`modules/${this.id}/`, {
        statut: (!this.available == true) ? 'available' : 'unavailable'
      }).then(response => {
        if(response.status == 200) {
          this.available = !this.available
          this.$emit('publish', this.available)
        }
      })
    },
    selectText (event) {
      window.getSelection().selectAllChildren(event.target);
    },
    tryDeleteModule () {
      if(this.name_check != this.data.title) {
        this.callbackPayload = 'Oups ! Champ incorrecte.' + '::-1::' + Date.now();
      }
      else{
        getAPI.delete(`micro_learning/courses/${this.id}`)
        .then(response => {
          if(response.status == 204) {
            this.callbackPayload = 'Micro learning supprimé avec succès' + '::' + Date.now();
            setTimeout(() => {
              this.$emit('sync')
            }, 1500)
          }
          else {
            this.displayError('Oups ! something went wrong.', 'alert-no')
            this.openLogoutBox = false
          }
        })
        .catch(() => {
          this.callbackPayload = 'Oups ! something went wrong.' + '::-1::' + Date.now();
        })
      }
    },
     timeToSeconds(timeString) {
    // Split the time string into hours, minutes, and seconds
    const [hours, minutes, seconds] = timeString.split(':').map(Number);

    // Convert hours, minutes, and seconds to total seconds
    const second = (hours * 3600) + (minutes * 60) + seconds;

    return second;
}, 
    onImageLoaded (imageData) {
      this.module_image_cover = imageData
    },
    joinStringToArray (s1, s2, s3) {
      let array = []
      if(s1 != "")
        array.push(`${s1}`)
      if(s2 != "")
        array.push(`${s2}`)
      if(s3 != "")
        array.push(`${s3}`)
      return array;
    },
    async updateThisModule () {
      if(this.module_title != '' && this.module_microlearning_url != '' && this.module_time != '' && this.module_description != '' && this.module_top_message != '' && this.module_sms_message != '') {
        let data = {
            title: this.module_title,
            description: this.module_description,
            micro_learning_url: this.module_microlearning_url,
            top_email_message: this.module_top_message,
            bottom_email_message: this.module_bottom_message,
            sms_message: this.module_sms_message,
            im_message: this.module_im_message,
            //duration: this.timeToSeconds(this.module_time),
            duration: this.module_time,
        }
        getAPI.patch(`micro_learning/courses/${this.id}/`, data).then((response) => {
          console.log(response)
          if(response.status == 200) {
            if(this.module_image_cover != null) {
              const formData = new FormData();
              formData.append('cover_image', this.module_image_cover);
              getAPI.patch(`micro_learning/courses/${this.id}/`, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              }).then(() => {
                this.$emit('update');
                let msg = 'Micro learning MAJ avec succès!'
                this.displayError(msg, 'alert-yes', 5000)
              })
            }
            else{
              this.$emit('update');
              let msg = 'Micro Learning MAJ avec succès!'
              this.displayError(msg, 'alert-yes', 5000)
            }
          }
          this.exitUpdateModal = Date.now()
        }).catch((error) => {
          console.log(error)
          if (error.response) {
            this.displayError('Oups ! something went wrong.', 'alert-no', 10000)
          }
          else if (error.request) {
            this.displayError('The request was made but no response was received. Please check your network.', 'alert-no', 10000)
          }
          else {
            this.displayError('Oups ! something went wrong.', 'alert-no', 10000)
          }
          this.exitUpdateModal = Date.now()
        });
      }
      else{
        this.displayError('Formulaire invalide ou incomplet', 'alert-no', 10000)
        this.alertString = `Formulaire invalide ou incomplet|alert-no|10000::`+Date.now()
        this.exitUpdateModal = Date.now()
      }
    }
  },
  watch: {
  }
}
</script>

<style scoped>
.label-c{
  position: relative;
  top: 0.1em;
  left: 0.3em;
}
.cfa-course-module-inner{
  border-radius: .5em;
}
.cfa-module-image{
  /* width: 500px; */
  border: 2px solid #f0f1f6;
  border-radius: .5em;
  overflow: hidden;
  min-height: 16em;
  background-color: #b5e5e7;
}
.cfa-module-image img{
  width: 100%;
  min-height: 250px;
  max-height: 250px;
  transition: all ease-in .3s;
  color: #b5e5e7;
}
.cfa-module-image img:hover{
  transform: scale(1.2) !important;
}
.gType{
  position: absolute;
  top: 0.5em;
  left: 0.5em;
}
.cfa-module{
  transition: all ease-in .3s;
}
.cfa-module-shadow{
  /* box-shadow: 20px 12px 109px -37px rgba(0,0,0,0.87);
  -webkit-box-shadow: 20px 12px 109px -37px rgba(0,0,0,0.87);
  -moz-box-shadow: 20px 12px 109px -37px rgba(0,0,0,0.87); */
  box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -webkit-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -moz-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
}
.descriptionArea{
  height: 135px;
  width: 100%;
  position: relative;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 600;
  outline: currentcolor none medium !important;
  border: 1px solid rgb(204, 204, 204);
  box-shadow: rgba(0, 0, 0, 0.075) 0px 1px 1px inset;
  padding: 6px 12px;
  background: rgb(247, 248, 252) none repeat scroll 0% 0%;
}
</style>
