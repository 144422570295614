export function getUpdatedKeys(firstObject, secondObject) {
    /*const updatedObject = {};

    for (let key in secondObject) {
        
        if (firstObject.hasOwnProperty(key) && firstObject[key] !== secondObject[key]) {
            // Add the key and its new value to the updatedObject
            updatedObject[key] = secondObject[key];
        }
    }

    return updatedObject;*/
    const changes = {};
      const allKeys = new Set([...Object.keys(firstObject), ...Object.keys(secondObject)]);
      allKeys.forEach((key) => {
        if (firstObject[key] !== secondObject[key]) {
          changes[key] = secondObject[key];
        }
      });
      return changes;
}